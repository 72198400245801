import axios from "axios";

import {
  GET_ALL_PRESTATION,
  GET_ALL_PRESTATION_CLIENT,
  POST_NEW_PRESTATION,
  UPDATE_PRESTATION,
  TOGGLE_PRESTATION,
} from "./types";

import { setErrors } from "./errorActions";

export const getAllPrestations = () => (dispatch) => {
  axios
    .get("/api/prestations/")
    .then((res) => {
      dispatch({
        type: GET_ALL_PRESTATION,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

export const getAllPrestationsClient = () => (dispatch) => {
  axios
    .get("/api/prestations/client")
    .then((res) => {
      dispatch({
        type: GET_ALL_PRESTATION_CLIENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

export const postNewPrestations = (presta) => (dispatch) => {
  dispatch(togglePrestation());
  axios
    .post("/api/prestations/new", { data: presta })
    .then((res) => {
      dispatch({
        type: POST_NEW_PRESTATION,
        payload: res.data,
      });
      dispatch(togglePrestation());
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(togglePrestation());
    });
};

export const updatePrestation = (presta) => (dispatch) => {
  dispatch(togglePrestation());
  axios
    .post("/api/prestations/update", { data: presta })
    .then((res) => {
      dispatch({
        type: UPDATE_PRESTATION,
        payload: res.data,
      });
      dispatch(togglePrestation());
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(togglePrestation());
    });
};

export const togglePrestation = () => {
  return {
    type: TOGGLE_PRESTATION,
  };
};
