import axios from "axios";

import { GET_ALL_TOWNS, GET_ALL_DEPARTMENTS } from "./types";

import { setErrors } from "./errorActions";

//A#1 : Get all departments
export const getAllDepartments = () => (dispatch) => {
  axios
    .get(`/api/data/departements`)
    .then((res) => {
      dispatch({
        type: GET_ALL_DEPARTMENTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

//A#1 : Get all departments
export const getAllTowns = (code) => (dispatch) => {
  axios
    .get(`/api/data/towns/${code}`)
    .then((res) => {
      dispatch({
        type: GET_ALL_TOWNS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};
