import React, { useEffect } from "react";
import isEmpty from "is-empty";

const DashboardAdmin = ({
  info_hairdresser,
  all_users,
  all_appointment_client,
}) => {
  useEffect(() => {
    document.body.classList.add("provider-body");
    return () => document.body.classList.remove("provider-body");
  }, []);
  const count_users = isEmpty(all_users) ? 0 : all_users[0];
  const presta_info = isEmpty(all_users) ? 0 : all_users[1];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h6>Tableau de bord</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <div className="dash-widget">
              <div className="dash-img">
                <span className="dash-icon bg-blue">
                  <img
                    src="../../assets/img/icons/user-icon01.svg"
                    alt="user"
                  />
                </span>
                <div className="dash-value">
                  <img
                    src="../../assets/img/icons/up-icon.svg"
                    alt="variation"
                  />
                  +10%
                </div>
              </div>
              <div className="dash-info">
                <div className="dash-order">
                  <h6>Total utilisateurs</h6>
                  <h5>{count_users}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <h6 className="user-title">Analyse par prestations</h6>
          <div className="col-md-6">
            <div className="table-responsive recent-booking flex-fill">
              {presta_info != 0 ? (
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Prestation</th>
                      <th scope="col">Prix moyen</th>
                    </tr>
                  </thead>
                  <tbody>
                    {presta_info.map((value, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <h2 className="table-avatar">
                              <span>
                                {/* <FeatherIcon icon={"dashboard"} /> */}

                                {value.name}
                              </span>
                            </h2>
                          </td>
                          <td>
                            <h2 className="table-avatar table-user">
                              <span>{value.prix_moyen}</span>
                            </h2>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div />
              )}
            </div>
          </div>
          <h6 className="user-title pt-4">KPI</h6>
          <div className="col-md-12">
            <div className="table-responsive recent-booking flex-fill">
              {!isEmpty(all_appointment_client) ? (
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Pro</th>
                      <th scope="col"># Rdv</th>
                      <th scope="col">URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all_appointment_client.map((value, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <h2 className="table-avatar">
                              <span>
                                {/* <FeatherIcon icon={"dashboard"} /> */}

                                {value.email}
                              </span>
                            </h2>
                          </td>
                          <td>
                            <h2 className="table-avatar table-user">
                              <span>{value.nb_booking}</span>
                            </h2>
                          </td>
                          <td>
                            <h2 className="table-avatar table-user">
                              <a href={value.url}>{value.url}</a>
                            </h2>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardAdmin;
