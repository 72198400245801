import { combineReducers } from "redux";
import stripeReducer from "./stripeReducers";
import toggleSubmit from "./messageReducers";
import errorReducer from "./errorReducers";
import hairdressReducer from "./hairdressReducers";
import appointmentReducer from "./appointmentReducers";
import authReducer from "./authReducers";
import dataReducer from "./dataReducers";
import hypoReducer from "./hypotheseReducers";
import prestationReducer from "./prestationReducers";
import functionnalityReducer from "./functionnalityReducers";
import productReducer from "./productReducers";
import clientReducer from "./clientReducers";

export default combineReducers({
  stripe: stripeReducer,
  hairdress: hairdressReducer,
  client: clientReducer,
  appointment: appointmentReducer,
  auth: authReducer,
  toggle: toggleSubmit,
  data: dataReducer,
  hypo: hypoReducer,
  prestation: prestationReducer,
  functionnality: functionnalityReducer,
  product: productReducer,
  errors: errorReducer,
});
