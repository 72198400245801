import {
  // CHECKOUT_SESSION,
  STRIPE_CREATE_PI,
  STRIPE_CREATE_PI_SERVICE,
  STRIPE_PI_STATUS,
  STRIPE_CONFIRM_PAYMENT,
  TOGGLE_STRIPE_LOADING,
  STRIPE_ALL_PI,
  STRIPE_PAYMENT_SUCCESS,
  STRIPE_CHECKOUT_SESSION,
  STRIPE_CHECKOUT_LOADING,
  STRIPE_ACCOUNT_ONBOARDING_URL,
  STRIPE_ACCOUNT_INFO,
} from "../actions/types";

const initialState = {
  sessionInfo: {},
  stripePaymentIntent: {},
  message: "",
  PI_confirmation: {},
  stripeLoading: false,
  stripe_pi_status: [],
  isPaymentSuccess: false,
  sessionId: {},
  isCheckoutSessionLoading: false,
  accountURLOnboarding: {},
  accountInfo: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STRIPE_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: { ...action.payload },
      };
    case STRIPE_ACCOUNT_ONBOARDING_URL:
      return {
        ...state,
        accountURLOnboarding: { ...action.payload },
      };
    case STRIPE_CREATE_PI_SERVICE:
      return {
        ...state,
        stripePaymentIntent: { ...action.payload },
      };
    case STRIPE_CHECKOUT_SESSION:
      return {
        ...state,
        sessionId: { ...action.payload },
      };
    case STRIPE_ALL_PI:
      return {
        ...state,
        stripe_pi_status: [...action.payload],
      };

    case TOGGLE_STRIPE_LOADING:
      return {
        ...state,
        stripeLoading: !state.stripeLoading,
      };

    case STRIPE_CONFIRM_PAYMENT:
      return {
        ...state,
        PI_confirmation: { ...action.payload },
      };

    case STRIPE_PI_STATUS:
      return {
        ...state,
        message: action.payload.message,
      };

    case STRIPE_CREATE_PI:
      return {
        ...state,
        stripePaymentIntent: { ...action.payload },
      };

    case STRIPE_CHECKOUT_LOADING: {
      return {
        ...state,
        isCheckoutSessionLoading: !state.isCheckoutSessionLoading,
      };
    }
    case STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        isPaymentSuccess: !state.isPaymentSuccess,
      };
    default:
      return state;
  }
}
