import {
   TOGGLE_HYP,
   GET_ALL_HYP
  } from "../actions/types";
  
  const initialState = {
      all_hypo:{},
      hyp_toggle:false
  };
  
  export default function(state = initialState, action) {
     switch (action.type) {

      case  GET_ALL_HYP:
         return {
            ...state,
            all_hypo:{...action.payload}
         }      
      case TOGGLE_HYP:
         return {
            ...state,
            toggle_hyp:!state.toggle_hyp
         }
 
       default:
          return state;
     }
  } 