import axios from "axios";
import { GET_CLIENT_INFO, TOGGLE_CLIENT, POST_BOOK_PRESTA } from "./types";

import { setErrors } from "./errorActions";

export const getClientInfo = () => (dispatch) => {
  axios
    .get(`/api/client/info`)
    .then((res) => {
      dispatch({
        type: GET_CLIENT_INFO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

export const postBookingPrestation = (data) => (dispatch) => {
  axios
    .post(`/api/appointments/booking`, data)
    .then((res) => {
      dispatch({
        type: POST_BOOK_PRESTA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

export const toggleClient = () => {
  return {
    type: TOGGLE_CLIENT,
  };
};
