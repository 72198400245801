import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  SET_CURRENT_CLIENT,
  TOGGLE_CLIENT_LOADING,
  TOGGLE_CLIENT_UPDATE,
} from "./types";

import { setErrors } from "./errorActions";

export const registerHairdress = (hairdressData, history) => (dispatch) => {
  dispatch(toggleHairdressLoading());
  axios
    .post("/api/hairdress/signup", hairdressData)
    .then((res) => {
      dispatch(toggleHairdressLoading());
      // localStorage.setItem("loginMessage", "Inscription validée");
      history("/connexion/pro");
    })
    .catch((err) => {
      dispatch(setErrors(err));
      dispatch(toggleHairdressLoading());
    });
};
export const registerClient = (clientData, history) => (dispatch) => {
  dispatch(toggleHairdressLoading());
  axios
    .post("/api/client/signup", clientData)
    .then((res) => {
      dispatch(toggleHairdressLoading());
      history("/connexion/client");
    })
    .catch((err) => {
      dispatch(setErrors(err));
      dispatch(toggleHairdressLoading());
    });
};

export const checkregisterHairdress = (hairdressData) => (dispatch) => {
  dispatch(toggleHairdressLoading());
  axios
    .post("/api/hairdress/checkSignup", hairdressData)
    .then((res) => {
      dispatch(toggleHairdressLoading());
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(toggleHairdressLoading());
    });
};

export const checkregisterClient = (clientData) => (dispatch) => {
  dispatch(toggleHairdressLoading());
  axios
    .post("/api/client/checkSignup", clientData)
    .then((res) => {
      dispatch(toggleHairdressLoading());
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(toggleHairdressLoading());
    });
};

export const loginHairdress = (hairdressData, history) => (dispatch) => {
  dispatch(toggleHairdressLoading());
  axios
    .post("/api/hairdress/login", hairdressData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);

      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentClient(decoded));
      dispatch(toggleHairdressLoading());
      window.location.reload();
      history("/board/pro");
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(toggleHairdressLoading());
    });
};
export const loginClient = (clientData, history) => (dispatch) => {
  dispatch(toggleHairdressLoading());
  axios
    .post("/api/client/login", clientData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);

      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentClient(decoded));
      dispatch(toggleHairdressLoading());
      window.location.reload();
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(toggleHairdressLoading());
    });
};

export const setCurrentClient = (hairdressData) => {
  return {
    type: SET_CURRENT_CLIENT,
    payload: hairdressData,
  };
};

export const toggleHairdressLoading = () => {
  return {
    type: TOGGLE_CLIENT_LOADING,
  };
};

export const toggleUpdateHairdress = () => {
  return {
    type: TOGGLE_CLIENT_UPDATE,
  };
};

export const logoutClient = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentClient({}));
  // window.location.reload();
  window.location.href = "/";
};
