import React, { useEffect } from "react";

const ProfilPro = ({
  info_hairdresser,
  onChange,
  onSubmit,
  onBlur,
  customer,
  all_departments,
  all_towns,
  displaySubmit,
}) => {
  useEffect(() => {
    document.body.classList.add("provider-body");
    return () => document.body.classList.remove("provider-body");
  }, []);

  const { department_id, town_id, errors } = customer;

  console.log({ department_id, town_id });
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="widget-title">
              <h4>Informations personnelles</h4>
            </div>
            <h6 className="user-title">General Information</h6>
            <div className="general-info">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">Prénom</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder={info_hairdresser.name}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">Nom</label>
                    <input
                      type="text"
                      name="surname"
                      className="form-control"
                      placeholder={info_hairdresser.surname}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">Pseudo</label>
                    <input
                      type="text"
                      name="username"
                      className="form-control"
                      placeholder={info_hairdresser.username}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                    <p style={{ color: "red" }}>{errors.username}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Email</label>
                    <input
                      name="email"
                      type="email"
                      style={{ border: "none" }}
                      className="form-control"
                      value={info_hairdresser.email}
                      placeholder={info_hairdresser.email}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">N°téléphone</label>
                    <input
                      name="phone_number"
                      type="text"
                      style={{ border: "none" }}
                      className="form-control"
                      value={info_hairdresser.phone_number}
                      placeholder={info_hairdresser.phone_number}
                    />
                  </div>
                </div>
              </div>
            </div>
            <h6 className="user-title">Adresse </h6>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">Département</label>
                  <select
                    onChange={onChange}
                    name="department_id"
                    className="form-select"
                    value={department_id}
                  >
                    {all_departments &&
                      all_departments.map((a) => (
                        <option
                          key={a.code}
                          value={a.code}
                          label={`${a.code} - ${a.nom}`}
                          style={{ textAlign: "center" }}
                        ></option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">Ville</label>

                  <select
                    onChange={onChange}
                    name="town_id"
                    className="form-select"
                    value={town_id}
                  >
                    {all_towns &&
                      all_towns.map((a) => (
                        <option
                          key={town_id}
                          value={a.codesPostaux[0]}
                          label={`${a.codesPostaux[0]} - ${a.nom}`}
                          style={{ textAlign: "center" }}
                        ></option>
                      ))}
                  </select>
                  {/* ) : (
                    <select
                      onChange={onChange}
                      name="town_id"
                      className="form-select"
                    >
                      <option style={{ textAlign: "center" }} selected>
                        * Villes *
                      </option>
                      {all_towns &&
                        all_towns.map((a) => (
                          <option
                            key={a.codesPostaux[0]}
                            value={
                              a.codesPostaux.length > 0 ? a.codesPostaux[0] : ""
                            }
                            label={
                              a.codesPostaux.length > 0
                                ? `${a.codesPostaux[0]} - ${a.nom}`
                                : "No data"
                            }
                            style={{ textAlign: "center" }}
                          ></option>
                        ))}
                    </select>
                  )} */}
                  {console.log("check :", department_id, department_id)}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <label className="col-form-label">
                  Déplacements chez le/la client(e)
                </label>
                <br />
                <input
                  type="checkbox"
                  name="atClient"
                  defaultChecked={info_hairdresser.atClient}
                  onClick={onChange}
                />
                {info_hairdresser.atClient ? " Oui" : " Non"}
              </div>
            </div>
            <div className="acc-submit">
              <button
                disabled={!displaySubmit}
                onClick={onSubmit}
                className="btn btn-primary w-80 login-btn"
              >
                Mettre à jour
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfilPro;
