import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";

const Sidebar = ({ clientObj, onLogOut }) => {
  const pathname = window.location.pathname;

  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };

  const url = clientObj.link;
  const [showAlert, setShowAlert] = useState(false);

  const copyTextToClipboard = () => {
    const textToCopy = `${url}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setShowAlert(true); // Activer l'alerte
        setTimeout(() => {
          setShowAlert(false); // Désactiver l'alerte après 5 secondes
        }, 1000);
      })
      .catch((err) => {
        console.error("Erreur lors de la copie : ", err);
      });
  };

  const isAdmin = clientObj.isAdmin;

  return (
    <>
      {/* Sidebar */}
      <div
        className="sidebar"
        id="sidebar"
        onMouseOver={expandMenuOpen}
        onMouseLeave={expandMenu}
      >
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-header">
            <div className="sidebar-logo">
              {isAdmin ? (
                <div />
              ) : (
                <>
                  <button
                    className="btn btn-outline-primary"
                    onClick={copyTextToClipboard}
                  >
                    <i
                      className="feather-link"
                      style={{ textEmphasis: "Highlight" }}
                    >
                      {" "}
                      <b>Lien Usouka</b>
                    </i>
                  </button>

                  {showAlert ? (
                    <div class="alert alert-info mt-2" role="alert">
                      Lien copié !
                    </div>
                  ) : (
                    <div />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              {isAdmin ? (
                <ul>
                  <li className="menu-title m-0">
                    <h6>Tour de contrôle</h6>
                  </li>
                  <li>
                    <Link
                      className={` ${
                        pathname === "/board/admin" ? "active" : ""
                      }`}
                      to="/board/admin"
                    >
                      <i className="fe fe-grid">
                        <FeatherIcon icon={"grid"} />
                      </i>{" "}
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li className="menu-title">
                    <h6>Prestations</h6>
                  </li>
                  <li>
                    <Link
                      className={` ${
                        pathname === "/admin/services" ? "active" : ""
                      }`}
                      to="/admin/services"
                    >
                      <i className="fe fe-hexagon">
                        <FeatherIcon icon={"hexagon"} />
                      </i>
                      <span>Services</span>
                    </Link>
                  </li>

                  <li>
                    <button
                      className="btn btn-outline-dark"
                      style={{
                        position: "fixed",
                        bottom: "20px", // Ajustez la distance du bas de la fenêtre selon vos besoins
                      }}
                      onClick={onLogOut}
                    >
                      <i className="feather-log-out"></i>{" "}
                      <span>Déconnexion</span>{" "}
                    </button>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li className="menu-title m-0">
                    <h6>Services</h6>
                  </li>
                  <li
                    className={` ${
                      pathname === "/board/services" ? "active" : ""
                    }`}
                  >
                    <Link to="/board/services">
                      <i className="fe fe-box">
                        <FeatherIcon icon={"box"} />
                      </i>{" "}
                      <span>Mes prestations</span>
                    </Link>
                  </li>
                  <li className="menu-title">
                    <h6>Calendrier</h6>
                  </li>
                  <li
                    className={` ${
                      pathname === "/board/calendrier" ? "active" : ""
                    }`}
                  >
                    <Link to="/board/calendrier">
                      <i className="fe fe-calendar">
                        <FeatherIcon icon={"calendar"} />
                      </i>
                      <span>Mes disponibilités</span>
                    </Link>
                  </li>
                  <li
                    className={` ${
                      pathname === "/board/rendez-vous" ? "active" : ""
                    }`}
                  >
                    <Link to="/board/rendez-vous">
                      <i className="fe fe-book-open">
                        <FeatherIcon icon={"book-open"} />
                      </i>
                      <span>Mes rendez-vous</span>
                    </Link>
                  </li>

                  <li className="menu-title">
                    <h6>Profil</h6>
                  </li>
                  <li
                    className={` ${
                      pathname === "/board/profil" ? "active" : ""
                    }`}
                  >
                    <Link to="/board/profil">
                      <i className="fe fe-user">
                        <FeatherIcon icon={"user"} />
                      </i>{" "}
                      <span>Profil</span>
                    </Link>
                  </li>

                  <li>
                    <button
                      className="btn btn-outline-dark"
                      style={{
                        position: "fixed",
                        bottom: "20px", // Ajustez la distance du bas de la fenêtre selon vos besoins
                      }}
                      onClick={onLogOut}
                    >
                      <i className="feather-log-out"></i>{" "}
                      <span>Déconnexion</span>{" "}
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* /Sidebar */}
    </>
  );
};
export default Sidebar;
