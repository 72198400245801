import React, { useEffect } from "react";
import isEmpty from "is-empty";

const ServicesAdmin = ({
  info_hairdresser,
  all_prestations,
  prestation,
  onChange,
  onSubmit,
  onChangeCheckbox,
}) => {
  useEffect(() => {
    document.body.classList.add("provider-body");
    return () => document.body.classList.remove("provider-body");
  }, []);

  const {
    presta_item,
    presta_item_selection,
    presta_isFeminin,
    presta_isNappy,
  } = prestation;
  const isEnabled = isEmpty(presta_item);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h6>Prestations de services</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h6 className="subhead-title">Prestations proposées</h6>
            {/* Prestations management */}
            <div className="row">
              <div className="col-md-12">
                <div className="booking-list">
                  <table className="table table-bordered">
                    <thead style={{ backgroundColor: "#e1e9eb" }}>
                      <tr style={{ textAlign: "center", width: "10px" }}>
                        <th
                          scope="col"
                          style={{
                            textAlign: "center",
                            width: "30%",
                            color: "black",
                          }}
                        >
                          Prestation
                        </th>

                        <th
                          scope="col"
                          style={{
                            textAlign: "center",
                            width: "30%",
                            color: "black",
                          }}
                        >
                          isNappy
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "center",
                            width: "30%",
                            color: "black",
                          }}
                        >
                          isFeminin
                        </th>

                        <th
                          scope="col"
                          style={{
                            textAlign: "center",
                            width: "5%",
                            color: "black",
                          }}
                        >
                          Actif
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "center",
                            width: "5%",
                            color: "black",
                          }}
                        >
                          Nb
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "center",
                            width: "5%",
                            color: "black",
                          }}
                        >
                          Ajouter
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          backgroundColor: "white",
                          textAlign: "center",
                        }}
                      >
                        <th scope="row">
                          <input
                            value={presta_item}
                            onChange={onChange}
                            name="presta_item"
                            style={{ width: "100%" }}
                          />
                        </th>

                        <td style={{ textAlign: "center" }}>
                          <input
                            name="presta_isNappy"
                            style={{
                              alignContent: "center",
                            }}
                            type="checkbox"
                            checked={presta_isNappy}
                            onChange={onChangeCheckbox}
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <input
                            name="presta_isFeminin"
                            style={{
                              alignContent: "center",
                            }}
                            type="checkbox"
                            checked={presta_isFeminin}
                            onChange={onChangeCheckbox}
                          />
                        </td>

                        <td style={{ textAlign: "center" }}>
                          <input
                            style={{
                              alignContent: "center",
                            }}
                            type="checkbox"
                            checked
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <button
                            className="btn btn-primary"
                            id="submit"
                            type="submit"
                            disabled={isEnabled}
                            onClick={(e) => onSubmit(e, "add")}
                          >
                            Valider
                          </button>
                        </td>
                      </tr>

                      <tr style={{ border: "transparent" }}>
                        <td
                          style={{
                            color: "rgba(0, 0, 0, 0)",
                            border: "transparent",
                          }}
                        >
                          x
                        </td>
                      </tr>

                      {all_prestations &&
                        all_prestations.map((a, b) => {
                          return (
                            <tr
                              key={b}
                              style={{
                                backgroundColor: a.isActive ? "" : "#f6f1f1",
                              }}
                            >
                              <th
                                scope="row"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "center",
                                    border: "transparent",
                                  }}
                                >
                                  {!a.isActive ? (
                                    <span
                                      style={{ paddingRight: "15px" }}
                                      alt="sleep"
                                    >
                                      💤
                                    </span>
                                  ) : (
                                    <div />
                                  )}

                                  <input
                                    style={{ width: "auto", border: "0px" }}
                                    name="presta_item_selection"
                                    type="text"
                                    id={a.id}
                                    placeholder={a.prestation}
                                    onChange={(e) =>
                                      onChange(e, "update", a.id)
                                    }
                                    className="ml-4"
                                  />

                                  {presta_item_selection === a.id ? (
                                    <button
                                      className="btn btn-success"
                                      id="delete"
                                      type="submit"
                                      onClick={(e) => onSubmit(e, "update")}
                                    >
                                      Update
                                    </button>
                                  ) : (
                                    <div />
                                  )}
                                </td>
                              </th>
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input
                                  style={{
                                    alignContent: "center",
                                  }}
                                  name="checkbox"
                                  type="checkbox"
                                  checked={a.isNappy}
                                  onClick={(e) =>
                                    onSubmit(e, "isNappy", a.id, !a.isNappy)
                                  }
                                />
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input
                                  style={{
                                    alignContent: "center",
                                  }}
                                  name="checkbox"
                                  type="checkbox"
                                  checked={a.isFeminin}
                                  onClick={(e) =>
                                    onSubmit(e, "isFeminin", a.id, !a.isFeminin)
                                  }
                                />
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input
                                  style={{
                                    alignContent: "center",
                                  }}
                                  name="checkbox"
                                  type="checkbox"
                                  checked={a.isActive}
                                  onClick={(e) =>
                                    onSubmit(e, "isActive", a.id, !a.isActive)
                                  }
                                />
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                x
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <button
                                  className="btn btn-danger"
                                  id="delete"
                                  type="submit"
                                  disabled
                                  onClick={(e) => onSubmit(e, "delete")}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServicesAdmin;
