const PATTERN_password =
  "^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+).([a-zA-Z]{2,5})$";
// const PATTERN_phone_number = `^((\\+)33|0)[1-9](\\d{2}){4}$`;
const PATTERN_phone_number = `^(06|07)[0-9]{8}$`;
const Validate = (name, value) => {
  let errors = {};

  switch (name) {
    case "road_number":
      errors.road_number =
        value.length === 0 ? "Veuillez saisir un numéro de rue/boulevard" : "";
      break;
    case "name":
      errors.name =
        value.length === 0
          ? "Veuillez saisir un prénom"
          : value.length < 3
          ? "Saisir un prénom avec minimum 3 caractères"
          : "";
      break;

    case "username":
      errors.username =
        value.length === 0
          ? "Veuillez saisir un pseudo"
          : value.length < 3
          ? "Saisir un pseudo avec minimum 3 caractères"
          : "";
      break;

    case "subject":
      errors.subject = value.length === 0 ? "Veuillez saisir un sujet" : "";
      break;

    case "address":
      errors.address = value.length === 0 ? "Veuillez saisir une adresse" : "";
      break;

    case "department_id":
      errors.department =
        value === "* Departement *" ? "Veuillez renseigner un département" : "";
      break;

    case "town_id":
      errors.town =
        value === "* Villes *" ? "Veuillez renseigner une ville" : "";
      break;

    case "client_email":
      errors.email =
        value.length === 0
          ? "Une adresse mail est nécessaire"
          : !value.match(PATTERN_password)
          ? "Le mail renseigné n'est pas au bon format"
          : "";
      break;

    case "email":
      errors.email =
        value.length === 0
          ? "Une adresse mail est nécessaire"
          : !value.match(PATTERN_password)
          ? "Le mail renseigné n'est pas au bon format"
          : "";
      break;

    case "password":
      errors.password =
        value.length === 0
          ? "Veuillez saisir un mot de passe"
          : value.length < 4
          ? "Saisir un mot de passe avec minimum 4 caractères"
          : "";
      break;

    case "profil_password":
      errors.profil_password =
        value.length === 0
          ? "Veuillez saisir un mot de passe"
          : value.length < 4
          ? "Saisir un mot de passe avec minimum 4 caractères"
          : "";
      break;

    case "phone_number":
      errors.phone_number =
        value.length === 0
          ? "Veuillez saisir un numéro de téléphone"
          : value.length < 10
          ? "Veuillez saisir un numéro de téléphone valide"
          : !value.match(PATTERN_phone_number)
          ? "Veuillez saisir un numéro de téléphone valide"
          : "";
      break;
    default:
      break;
  }

  return {
    errors,
  };
};

export default Validate;
