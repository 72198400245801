import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Login from "./../../../components/layout/auth/Login";

import Validate from "../../../utils/form/Validate";
import { loginHairdress, loginClient } from "./../../../actions/authActions";

import { useNavigate } from "react-router-dom";

const LoginPage = ({ loginHairdress, loginClient, auth, errors }) => {
  const location = window.location.pathname;
  const isPro = location.includes("pro");

  let history = useNavigate();

  const [client, setClient] = useState({
    email: "",
    password: "",
    errors: {},
  });

  useEffect(() => {
    setClient((client) => {
      return { ...client, errors };
    });
  }, [errors]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      if (auth.clientObj.isAdmin) {
        history("/board/admin");
      } else {
        isPro ? history("/board/services") : history("/board/client");
      }
    }
  }, [auth]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClient((client) => ({ ...client, [name]: value }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = { ...client.errors, ...Validate(name, value).errors };
    setClient({ ...client, errors: { ...error } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = client;
    isPro
      ? loginHairdress({ email, password }, history)
      : loginClient({ email, password }, history);
  };

  return (
    <Login
      // title=
      client={client}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onBlur={handleBlur}
      isPro={isPro}
    />
  );
};

LoginPage.protTypes = {
  errors: PropTypes.object.isRequired,
  loginHairdress: PropTypes.func.isRequired,
  loginClient: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  loginHairdress,
  loginClient,
})(LoginPage);
