import React from "react";

const Home = () => {
  return (
    <section className="hero-section">
      <div className="container">
        <div className="home-banner">
          <div className="row align-items-center w-100">
            <div className="col-lg-7 col-md-10 mx-auto">
              <div className="section-search aos" data-aos="fade-up">
                <h1>
                  Optimisez votre{" "}
                  <div style={{ display: "inline", color: "purple" }}>
                    Planning{" "}
                  </div>{" "}
                  <br />
                  &amp; boostez votre activité dans la
                  <div style={{ display: "inline", color: "purple" }}>
                    {" "}
                    Coiffure
                  </div>
                </h1>
                <p>Plateforme dédiée aux professionnels de la coiffure</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="section-heading aos" data-aos="fade-up">
              <h2>Comment Usouka fonctionne ?</h2>
              <p>Une fois votre inscription réalisée : </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="work-box aos" data-aos="fade-up">
              <div className="work-icon">
                <span>
                  <img
                    src="/assets/img/icons/feature-calendar.svg"
                    alt="calendar"
                  />
                </span>
              </div>
              <h5>Planifiez Facilement</h5>
              <p>
                Indiquez vos créneaux disponibles et gérez votre temps
                efficacement.
              </p>
              <h4 style={{ color: "#9696d9" }}>01</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="work-box aos" data-aos="fade-up">
              <div className="work-icon">
                <span>
                  <img src="/assets/img/icons/feature-comb.svg" alt="comb" />
                </span>
              </div>
              <h5>Vos services, votre tarif</h5>
              <p>
                Listez vos prestations et fixez vos prix pour offrir clarté et
                choix à vos clients.
              </p>
              <h4 style={{ color: "#9696d9" }}>02</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="work-box aos" data-aos="fade-up">
              <div className="work-icon">
                <span>
                  <img
                    src="/assets/img/icons/feature-share.svg"
                    alt="calendar"
                  />
                </span>
              </div>
              <h5>Boostez votre visibilité</h5>
              <p>
                Peaufinez et partagez votre profil Usouka pour attirer plus de
                clients.
              </p>
              <h4 style={{ color: "#9696d9" }}>03</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Home;
