import { GET_CLIENT_INFO, TOGGLE_CLIENT } from "../actions/types";

const initialState = {
  info_client: {},
  client_toggl: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_INFO:
      return {
        ...state,
        info_client: { ...action.payload },
      };
    case TOGGLE_CLIENT:
      return {
        ...state,
        client_toggl: !state.client_toggl,
      };
    default:
      return state;
  }
}
