import axios from "axios";

import { TOGGLE_APPOINTMENT, GET_ALL_APPOINTMENT_CLIENT } from "./types";

import { setErrors } from "./errorActions";

//A#1 : All meetings for admin dashboard
export const getAllAppointmentClient = () => (dispatch) => {
  axios
    .get(`/api/appointments/client/admin`)
    .then((res) => {
      dispatch({
        type: GET_ALL_APPOINTMENT_CLIENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

export const toggleAppointments = () => {
  return {
    type: TOGGLE_APPOINTMENT,
  };
};
