import {
  GET_ALL_PRESTATION,
  TOGGLE_PRESTATION,
  GET_ALL_PRESTATION_CLIENT,
} from "../actions/types";

const initialState = {
  all_prestations: [],
  all_prestations_client: [],
  prestation_toggle: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PRESTATION_CLIENT:
      return {
        ...state,
        all_prestations_client: [...action.payload],
      };
    case GET_ALL_PRESTATION:
      return {
        ...state,
        all_prestations: [...action.payload],
      };
    case TOGGLE_PRESTATION:
      return {
        ...state,
        prestation_toggle: !state.prestation_toggle,
      };

    default:
      return state;
  }
}
