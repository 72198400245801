import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";
import PrivateRoute from "./utils/PrivateRoute";
import HomePage from "./containers/layout/HomePage";
import TermsCoPage from "./containers/layout/TermsCoPage";
import Header from "./components/layout/Header";

import ProviderSignupPage from "./containers/layout/auth/ProviderSignupPage";
import LoginPage from "./containers/layout/auth/LoginPage";

import DashboardAdminPage from "./containers/admin/DashboardAdminPage";

import ServicesProPage from "./containers/clients/ServicesProPage";
import ServicesAdminPage from "./containers/admin/ServicesAdminPage";

import PlanningProPage from "./containers/clients/PlanningProPage";
import BookingProPage from "./containers/clients/BookingProPage";
import ProfilProPage from "./containers/clients/ProfilProPage";
import HairdressPage from "./containers/layout/HairdressPage";
import FooterPage from "./containers/layout/FooterPage";

import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import { setCurrentClient, logoutClient } from "./actions/authActions";

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentClient(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutClient());
    window.location.href = "./";
  }
}

const ProtectedRoute = ({ element: Element, isAdmin, isAuthenticated }) => {
  return isAuthenticated ? (
    isAdmin ? (
      <Navigate to="/board/admin" />
    ) : (
      <Navigate to="/board/services" />
    )
  ) : (
    <Element />
  );
};
const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Header
          auth={store.getState().auth}
          isAdmin={store.getState().auth.clientObj.isAdmin}
        />
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute
                element={HomePage}
                isAdmin={store.getState().auth.clientObj.isAdmin}
                isAuthenticated={store.getState().auth.isAuthenticated}
              />
            }
          />
          <Route path="/terms_conditions" element={<TermsCoPage />} />

          <Route path="/inscription/pro" element={<ProviderSignupPage />} />
          <Route path="/connexion/pro" element={<LoginPage />} />
          <Route path="/profil/:username" element={<HairdressPage />} />

          <Route exact path="/board/services" element={<PrivateRoute />}>
            <Route exact path="/board/services" element={<ServicesProPage />} />
          </Route>
          <Route exact path="/board/profil" element={<PrivateRoute />}>
            <Route exact path="/board/profil" element={<ProfilProPage />} />
          </Route>
          <Route exact path="/board/calendrier" element={<PrivateRoute />}>
            <Route
              exact
              path="/board/calendrier"
              element={<PlanningProPage />}
            />
          </Route>
          <Route exact path="/board/rendez-vous" element={<PrivateRoute />}>
            <Route
              exact
              path="/board/rendez-vous"
              element={<BookingProPage />}
            />
          </Route>

          <Route exact path="/board/admin" element={<PrivateRoute />}>
            <Route exact path="/board/admin" element={<DashboardAdminPage />} />
          </Route>

          <Route exact path="/admin/services" element={<PrivateRoute />}>
            <Route
              exact
              path="/admin/services"
              element={<ServicesAdminPage />}
            />
          </Route>

          <Route
            path="*"
            element={
              <Navigate
                to={
                  store.getState().auth.isAuthenticated
                    ? store.getState().auth.clientObj.isAdmin
                      ? "/board/admin"
                      : "/board/services"
                    : "/"
                }
              />
            }
          />
        </Routes>
        <FooterPage />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
