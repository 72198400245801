import React from "react";
import { Link } from "react-router-dom";
import isEmpty from "is-empty";

const ProviderSignup = ({
  // GENERAL
  customer,
  // PROFIL
  onChangeInfo,
  onChangeCheck,
  onBlurInfo,
  signupHairdress,
  all_departments,
  all_towns,
}) => {
  const {
    name,
    surname,
    username,
    email,
    department_id,
    town_id,
    phone_number,
    password,
    password_confirmation,
    atClient,
    acceptCGU,
    errors,
  } = customer;

  function isValidCustomer({
    name,
    surname,
    username,
    email,
    department_id,
    town_id,
    phone_number,
    password,
    password_confirmation,
    atClient,
    acceptCGU = true, // Par défaut, acceptCGU est true si non défini explicitement
    errors,
  }) {
    return (
      name &&
      surname &&
      username &&
      email &&
      department_id &&
      town_id &&
      phone_number &&
      password &&
      password_confirmation &&
      atClient &&
      acceptCGU === true &&
      Object.values(errors).every((error) => isEmpty(error))
    );
  }

  const isCustomerValid = isValidCustomer({
    name,
    surname,
    username,
    email,
    department_id,
    town_id,
    phone_number,
    password,
    password_confirmation,
    atClient,
    acceptCGU,
    errors,
  });
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-back">
                  <Link to="/">
                    <i className="fa-solid fa-arrow-left me-2" /> Retour à
                    l'accueil
                  </Link>
                </div>
                <div className="login-header">
                  <div className="text-center">
                    <h3>Inscription professionnel</h3>
                  </div>
                </div>
                {/* Signup Form */}
                <form action="/provider-dashboard">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Prénom</label>
                        <input
                          type="text"
                          name="name"
                          value={name}
                          className="form-control"
                          placeholder="Saisir un prénom"
                          onChange={onChangeInfo}
                          onBlur={onBlurInfo}
                        />
                        <p style={{ color: "red" }}>{errors.name}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Nom de famille</label>
                        <input
                          type="text"
                          name="surname"
                          value={surname}
                          className="form-control"
                          placeholder="Saisir un nom de famille"
                          onChange={onChangeInfo}
                          onBlur={onBlurInfo}
                        />
                        <p style={{ color: "red" }}>{errors.surname}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Pseudo</label>
                        <input
                          type="text"
                          name="username"
                          value={username}
                          className="form-control"
                          placeholder="Pseudo"
                          onChange={onChangeInfo}
                          onBlur={onBlurInfo}
                        />
                      </div>
                      <p style={{ color: "red" }}>{errors.username}</p>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Adresse mail</label>
                        <input
                          type="email"
                          name="email"
                          value={email}
                          className="form-control"
                          placeholder="exemple@email.com"
                          onChange={onChangeInfo}
                          onBlur={onBlurInfo}
                        />
                        <p style={{ color: "red" }}>{errors.email}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-7">
                      <div className="form-group">
                        <label className="col-form-label">Téléphone</label>
                        <input
                          type="number"
                          name="phone_number"
                          value={phone_number}
                          className="form-control"
                          placeholder="06 12 34 56 78"
                          maxLength="10"
                          onChange={onChangeInfo}
                          onBlur={onBlurInfo}
                        />
                        <p style={{ color: "red" }}>{errors.phone_number}</p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Département</label>
                        <select
                          onChange={onChangeInfo}
                          onBlur={onBlurInfo}
                          name="department_id"
                          className="form-select"
                        >
                          <option style={{ textAlign: "center" }} selected>
                            * Departement *
                          </option>
                          {all_departments &&
                            all_departments.map((a) => (
                              <option
                                value={a.code}
                                label={`${a.code} - ${a.nom}`}
                                style={{ textAlign: "center" }}
                              ></option>
                            ))}
                        </select>
                        <p style={{ color: "red" }}>{errors.department}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Ville</label>
                        <select
                          onChange={onChangeInfo}
                          onBlur={onBlurInfo}
                          name="town_id"
                          className="form-select"
                        >
                          <option style={{ textAlign: "center" }} selected>
                            * Villes *
                          </option>
                          {all_towns &&
                            all_towns.map((a) => (
                              <option
                                value={a.codesPostaux[0]}
                                label={`${a.codesPostaux[0]} - ${a.nom}`}
                                style={{ textAlign: "center" }}
                              ></option>
                            ))}
                        </select>
                        <p style={{ color: "red" }}>{errors.town}</p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Acceptez - vous de vous <b>déplacer chez </b>le client
                          ?
                        </label>

                        <input
                          type="checkbox"
                          name="atClient"
                          checked={atClient}
                          onChange={onChangeCheck}
                          onBlur={onBlurInfo}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-form-label d-block">
                      Mot de passe
                      <span className="brief-bio float-end">
                        Saisir au moins 8 caractères
                      </span>
                    </label>
                    <div className="pass-group">
                      <input
                        type="password"
                        name="password"
                        className="form-control pass-input"
                        placeholder="**********"
                        onChange={onChangeInfo}
                        onBlur={onBlurInfo}
                      />
                    </div>
                    <p style={{ color: "red" }}>{errors.password}</p>
                  </div>

                  <div className="form-group">
                    <label className="col-form-label d-block">
                      Confirmation de mot de passe
                    </label>
                    <div className="pass-group">
                      <input
                        type="password"
                        name="password_confirmation"
                        className="form-control pass-input"
                        placeholder="**********"
                        onChange={onChangeInfo}
                        onBlur={onBlurInfo}
                      />
                      <p style={{ color: "red" }}>
                        {isEmpty(password_confirmation)
                          ? ""
                          : password === password_confirmation
                          ? ""
                          : "Les mots de passes doivent être identiques."}
                      </p>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="col-form-label">
                            Je reconnais avoir lu et compris les{" "}
                            <a
                              alt="terms et conditions"
                              href="/terms_conditions"
                              style={{ color: "violet" }}
                            >
                              CGU{" "}
                            </a>
                            et je les accepte
                          </label>
                          <br />
                          <input
                            type="checkbox"
                            name="acceptCGU"
                            onChange={onChangeCheck}
                            onBlur={onBlurInfo}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={signupHairdress}
                    disabled={isCustomerValid ? false : true}
                    className="btn btn-success w-100 login-btn"
                  >
                    Inscription
                  </button>

                  <div className="login-or">
                    <span className="or-line" />
                    <span className="span-or">Vous avez déjà un compte ?</span>
                  </div>
                  <div className="social-login">
                    <Link to="/connexion/pro" className="btn btn-back w-100">
                      Connexion
                    </Link>
                  </div>
                </form>
                {/* /Signup Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProviderSignup;
