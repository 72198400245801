import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProviderSignup from "./../../../components/layout/auth/ProviderSignup";
import Validate from "./../../../utils/form/Validate";
import {
  registerHairdress,
  checkregisterHairdress,
} from "./../../../actions/authActions";

import { getAllDepartments, getAllTowns } from "./../../../actions/dataActions";

import { useNavigate } from "react-router-dom";

const ProviderSignupPage = ({
  registerHairdress,
  checkregisterHairdress,
  getAllDepartments,
  all_departments,
  getAllTowns,
  all_towns,
  auth,
  errors,
}) => {
  let history = useNavigate();
  const [customer, setCustomer] = useState({
    name: "",
    surname: "",
    username: "",
    email: "",
    phone_number: "",
    address: "",
    password: "",
    road_number: 0,
    department_id: "",
    town_id: "",
    atClient: true,
    acceptCGU: false,
    password_confirmation: "",
    errors: {},
  });

  useEffect(() => {
    setCustomer((customer) => {
      return { ...customer, errors };
    });
  }, [errors]);

  useEffect(() => {
    getAllDepartments();
  }, [getAllDepartments]);

  useEffect(() => {
    if (customer.department_id) {
      getAllTowns(customer.department_id);
    }
  }, [customer.department_id, getAllTowns]);

  const handleChangeInfo = (e) => {
    e.preventDefault();
    const { name, value, maxLength, checked } = e.target;

    switch (name) {
      case "phone_number":
        setCustomer({
          ...customer,
          ["phone_number"]: value.slice(0, maxLength),
        });

        break;
      case "atClient":
        setCustomer({
          ...customer,
          ["atClient"]: checked,
        });
        break;

      default:
        setCustomer({ ...customer, [name]: value });
        break;
    }
  };

  const handleChangeCheck = (e) => {
    const { name, value, maxLength, checked } = e.target;

    setCustomer({ ...customer, [name]: checked });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    name == "email"
      ? checkregisterHairdress({ name, value, type: name })
      : console.log("OK");

    name == "username"
      ? checkregisterHairdress({ name, value, type: name })
      : console.log("OK");

    const error = { ...customer.errors, ...Validate(name, value).errors };
    setCustomer({ ...customer, errors: { ...error } });
  };

  // SIGNUP HAIRDRESS
  const handleSignupHairdress = (e) => {
    e.preventDefault();

    const department_name = all_departments
      .filter((a) => a.code === customer.department_id)
      .map((b) => b.nom)[0];

    const town_name = all_towns
      .filter((a) => a.codesPostaux[0] === customer.town_id)
      .map((b) => b.nom)[0];

    registerHairdress({ ...customer, department_name, town_name }, history);
  };

  return (
    <ProviderSignup
      // GENERAL
      customer={customer}
      // PROFIL
      onChangeInfo={handleChangeInfo}
      onChangeCheck={handleChangeCheck}
      onBlurInfo={handleBlur}
      signupHairdress={handleSignupHairdress}
      all_departments={all_departments}
      all_towns={all_towns}
    />
  );
};

ProviderSignupPage.protTypes = {
  errors: PropTypes.object.isRequired,
  registerHairdress: PropTypes.func.isRequired,
  checkregisterHairdress: PropTypes.func.isRequired,
  getAllDepartments: PropTypes.func.isRequired,
  getAllTowns: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
  all_departments: state.data.all_departments,
  all_towns: state.data.all_towns,
});
export default connect(mapStateToProps, {
  registerHairdress,
  checkregisterHairdress,
  getAllDepartments,
  getAllTowns,
})(ProviderSignupPage);
