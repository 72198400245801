import React from "react";
import isEmpty from "is-empty";
import moment from "moment";
import { Cloudinary } from "@cloudinary/url-gen";
import { fill, imaggaScale } from "@cloudinary/url-gen/actions/resize";

import "moment/locale/fr";

export function currencyFormat(number) {
  const thisNumber = number / 100;
  return Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(thisNumber);
}
export function rating(rate) {
  const grey_array = [...Array(rate[0]).keys()].map((a) => (
    <span style={{ filter: "grayscale(100%)" }} key={a}>
      ⭐
    </span>
  ));

  const color_array = [...Array(rate[1]).keys()].map((a) => (
    <span className="default" key={a}>
      ⭐
    </span>
  ));

  return color_array.concat(grey_array);
}

export function currencyFormat_other(number) {
  const thisNumber = number;
  const final_number = Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(thisNumber);

  if (thisNumber === 0) {
    return "-";
  }

  return final_number;
}

export function space_string(value) {
  return Array(value).fill("\xa0").join("");
}

export function convertDay(day) {
  switch (day) {
    case "lundi":
      return "a";

    case "mardi":
      return "b";

    case "mercredi":
      return "c";

    case "jeudi":
      return "d";

    case "vendredi":
      return "e";

    case "samedi":
      return "f";

    case "dimanche":
      return "g";

    default:
      break;
  }
}

export function time_slot() {
  const x = {
    slotInterval: 30,
    openTime: "08:00",
    closeTime: "20:30",
  };

  //Format the time
  const startTime = moment(x.openTime, "HH:mm");

  //Format the end time
  const endTime = moment(x.closeTime, "HH:mm");

  //Times
  const allTimes = [];

  //Loop over the times - only pushes time with 30 minutes interval
  while (startTime < endTime) {
    allTimes.push(startTime.format("HH:mm"));
    startTime.add(x.slotInterval, "minutes");
  }

  // const rows = allTimes.reduce(function (rows, key, index) {
  //     return (index % 2 == 0 ? rows.push([key])
  //     : rows[rows.length-1].push(key)) && rows;
  // }, []);

  // rows.map(a=> slotTimes.push(`${a[0]} - ${a[1]}`))

  return allTimes
    .map((item, index) =>
      allTimes[index + 1] ? item + " - " + allTimes[index + 1] : ""
    )
    .filter((a) => !isEmpty(a));
}

export function date_format(date) {
  const new_format = moment(date).format("MM/DD/YYYY");
  return new_format;
}

export function identify_slot(data, date_selected) {
  // STEP #1 : check if slots are already booked
  const step_one = !isEmpty(data[0])
    ? data[0].slots
        .filter((a) => a[0] === date_format(date_selected))
        .map((b) => b[1])
        .sort((c, d) => c.localeCompare(d))
    : "";

  const nb_item = step_one.length;

  // STEP #2 : identify slots that are already booked
  const step_two = isEmpty(step_one)
    ? []
    : [...Array(nb_item).keys()].map((a) => [
        moment(step_one[a], "H:mm").format("H:mm"),
        // moment(step_one[a],'H:mm').add('30','minutes').format('H:mm'),
        moment(step_one[a], "H:mm").add("30", "minutes").format("H:mm"),
        // moment(step_one[a],'H:mm').add('90','minutes').format('H:mm')
      ]);
  return Object.values(step_two).flat();
}

export function slot_from_to(data, date_selected) {
  const step_one = !isEmpty(data[0])
    ? data[0].slots
        .filter((a) => a[0] === date_format(date_selected))
        .map((b) => b[1])
        .sort((c, d) => c.localeCompare(d))
    : "";
  const nb_item = step_one.length;

  const step_two = isEmpty(step_one)
    ? []
    : [...Array(nb_item).keys()].map((a) => [
        moment(step_one[a], "H:mm").format("H:mm"),
        // moment(step_one[a],'H:mm').add('30','minutes').format('H:mm'),
        moment(step_one[a], "H:mm").add("30", "minutes").format("H:mm"),
        // moment(step_one[a],'H:mm').add('90','minutes').format('H:mm')
      ]);

  return step_two.map((a) => [
    [date_format(date_selected), a[0], a[1]],
    `${a[0]} - ${a[1]}`,
  ]);
}

export function slot_from_to_new(data, date_selected) {
  return isEmpty(data[0])
    ? []
    : data[0].slots_booked.filter((a) => a[0] === date_format(date_selected));
}

export function string_to_object(string) {
  //#Instructions :  convert searchParam URL from string to object to extract a specific parameter

  const subString = string.slice(1);

  return JSON.parse(
    '{"' +
      decodeURI(subString)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
}

export function delete_duplicates(array) {
  // supprimer les doublons
  const uniqueArray = array.filter(function (elem, pos) {
    return array.indexOf(elem) == pos;
  });

  return uniqueArray;
}

export function display_pic_cloudinary(item, w, h) {
  const cld = new Cloudinary({
    cloud: {
      cloudName: "duuamjvt3",
    },
  });

  // return cld.image(item).toURL()
  return cld.image(item).resize(fill().width(w).height(h)).toURL();
}
