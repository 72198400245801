import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { logoutClient } from "../../actions/authActions";

const Header = ({ auth, isAdmin, logoutClient }) => {
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);
  const [menu3, setMenu3] = useState(false);

  const url = auth.clientObj.link;

  const isMobile = useMediaQuery({ maxWidth: 990 });

  const location = useLocation();
  const pathname = location.pathname;

  const onHandleMobileMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
  };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const copyTextToClipboard = () => {
    const textToCopy = `${url}`; // Remplacez cette chaîne par la vôtre
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Copié");
      })
      .catch((err) => {
        console.error("Erreur lors de la copie : ", err);
      });
  };

  const path_logo = auth.isAuthenticated
    ? isAdmin
      ? "/board/admin"
      : "/board/services"
    : "/";

  return (
    <>
      <header className="header" onMenuClick={(value) => toggleMobileMenu()}>
        <div className="container">
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
              <Link
                id="mobile_btn"
                to={path_logo}
                onClick={() => onHandleMobileMenu()}
              >
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to={path_logo} className="navbar-brand logo">
                <img
                  src="/assets/img/usouka_logo.png"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
              <Link to={path_logo} className="navbar-brand logo-small">
                <img
                  src="/assets/img/usouka_logo.png"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
            </div>

            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to={path_logo} className="menu-logo">
                  <img
                    src="/assets/img/usouka_logo.png"
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#"
                  onClick={() => onhandleCloseMenu()}
                >
                  {" "}
                  <i className="fas fa-times" />
                </Link>
              </div>
              <ul className="main-nav">
                {isMobile ? (
                  auth.isAuthenticated ? (
                    <>
                      <li className="has-submenu">
                        <Link
                          to="/board/services"
                          className={menu1 ? "submenu" : ""}
                        >
                          Mes services
                          <i
                            className="fas fa-chevron-down"
                            onClick={() => setMenu1(!menu1)}
                          />
                        </Link>
                      </li>
                      <li
                        className={`has-submenu ${
                          pathname === "/board/calendrier" ? "active" : ""
                        }`}
                      >
                        <Link
                          to="/board/calendrier"
                          className={menu2 ? "submenu" : ""}
                        >
                          Mes disponibilités
                          <i
                            className="fas fa-chevron-down"
                            onClick={() => setMenu2(!menu2)}
                          />
                        </Link>
                      </li>
                      <li className="has-submenu">
                        <Link
                          to="/board/profil"
                          className={menu3 ? "submenu" : ""}
                        >
                          Profil
                          <i
                            className="fas fa-chevron-down"
                            onClick={() => setMenu3(!menu3)}
                          />
                        </Link>
                      </li>
                      <li className="has-submenu">
                        <button
                          className="btn btn-outline-dark"
                          onClick={() => logoutClient()}
                        >
                          <i className="feather-log-out"></i>{" "}
                          <span>Déconnexion</span>
                        </button>
                      </li>

                      <button
                        className="btn btn-outline-dark"
                        style={{
                          position: "fixed",
                          bottom: "20px", // Ajustez la distance du bas de la fenêtre selon vos besoins
                          left: "50%", // Centrez l'élément horizontalement
                          transform: "translateX(-50%)", // Centrez l'élément horizontalement
                          zIndex: "999", // Assurez-vous que l'élément est au-dessus des autres contenus si nécessaire
                        }}
                        onClick={copyTextToClipboard}
                      >
                        <i
                          className="feather-link"
                          style={{ textEmphasis: "Highlight" }}
                        >
                          {" "}
                          <b>Mon profil Usouka</b>
                        </i>{" "}
                        <span>{url}</span>
                      </button>
                    </>
                  ) : (
                    <>
                      <li className="nav-item">
                        <Link
                          className="nav-link header-reg"
                          to="/inscription/pro"
                        >
                          Inscription
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link header-login"
                          to="/connexion/pro"
                        >
                          <i className="fa-regular fa-circle-user me-2" />
                          Connexion
                        </Link>
                      </li>
                    </>
                  )
                ) : (
                  <div />
                )}
              </ul>
            </div>

            {auth.isAuthenticated ? (
              <div />
            ) : (
              <ul className="nav header-navbar-rht">
                <li className="nav-item">
                  <Link className="nav-link header-reg" to="/inscription/pro">
                    Inscription
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link header-login" to="/connexion/pro">
                    <i className="fa-regular fa-circle-user me-2" />
                    Connexion
                  </Link>
                </li>
              </ul>
            )}
          </nav>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  logoutClient: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { logoutClient })(Header);
