import React, { useEffect } from "react";

// import { Link } from "react-router-dom";
// import isEmpty from "is-empty";
import { date_format } from "../../utils/dashboardFunction";

import Calendar from "react-calendar";
import "./Calendar.css";
import moment from "moment";
import "moment/locale/fr";

const PlanningPro = ({
  info_hairdresser,
  onCalendarChange,
  availability,
  onClickSlot,
}) => {
  useEffect(() => {
    document.body.classList.add("provider-body");
    return () => document.body.classList.remove("provider-body");
  }, []);
  const { date } = availability;
  const last_day = moment()
    .add(1, "months")
    .endOf("month")
    .format("MM-DD-YYYY");

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h6>Mes disponibilités</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 d-flex flex-column">
            <h6 className="subhead-title">Calendrier</h6>
            <div className="card flex-fill">
              <div className="card-body">
                <Calendar
                  minDate={new Date()}
                  maxDate={new Date(last_day)}
                  onChange={onCalendarChange}
                  locale="fr-FR"
                  tileClassName={({ date, view }) => {
                    const formattedDate = moment(date).format("MM/DD/YYYY");

                    const isOpen =
                      info_hairdresser.open_dates?.includes(formattedDate);
                    const isBooked =
                      info_hairdresser.booked_dates?.includes(formattedDate);

                    if (isOpen && isBooked) {
                      return "open_and_booked";
                    } else if (isOpen) {
                      return "open_slot";
                    } else if (isBooked) {
                      return "booked";
                    }
                  }}
                />

                <div className="calendar-legend pt-2">
                  <div className="legend-item">
                    <span
                      className="legend-color"
                      style={{ backgroundColor: "#17C643" }}
                    ></span>
                    <span className="legend-text">Créneaux ouverts</span>
                  </div>
                  <div className="legend-item">
                    <span
                      className="legend-color"
                      style={{ backgroundColor: "#deb630" }}
                    ></span>
                    <span className="legend-text">Créneaux réservés</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-8 d-flex flex-column"
            style={{ maxHeight: "450px", overflowY: "auto" }}
          >
            <h6 className="subhead-title">Gestion des disponibilités</h6>
            <div className="card flex-fill">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card-body">
                    <h5 className="text-center">
                      <span style={{ marginRight: "3%" }} alt="availabe">
                        ⚪
                      </span>
                      Disponibles
                    </h5>{" "}
                    {info_hairdresser.available_slots &&
                      info_hairdresser.available_slots.map((a) => (
                        <ul
                          onClick={(e) =>
                            onClickSlot(e, "add", [date_format(date), a])
                          }
                          className="list-group"
                        >
                          <li
                            id={a}
                            style={{
                              textAlign: "center",
                              marginBottom: "5px",
                            }}
                            className="list-group-item list-group-item-action"
                          >
                            {a}
                            <span
                              style={{
                                position: "absolute", // Positionnement de la croix
                                top: "2px",
                                right: "10px",
                                cursor: "pointer", // Style de curseur pour indiquer la possibilité de cliquer
                              }}
                            >
                              ➕
                            </span>
                          </li>
                        </ul>
                      ))}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card-body">
                    <h5 className="text-center">
                      <span style={{ marginRight: "3%" }} alt="open">
                        🟢
                      </span>
                      Ouverts
                    </h5>
                    {info_hairdresser.open_slots &&
                      info_hairdresser.open_slots.map((a, b) => (
                        <ul
                          onClick={(e) =>
                            onClickSlot(e, "delete", [date_format(date), a])
                          }
                          className="listgroup"
                        >
                          <li
                            id={b}
                            style={{
                              backgroundColor: "green",
                              color: "white",
                              textAlign: "center",
                              marginBottom: "5px",
                            }}
                            className="list-group-item list-group-item-action"
                          >
                            {a}
                            <span
                              style={{
                                position: "absolute", // Positionnement de la croix
                                top: "2px",
                                right: "10px",
                                cursor: "pointer", // Style de curseur pour indiquer la possibilité de cliquer
                              }}
                            >
                              ✖
                            </span>
                          </li>
                        </ul>
                      ))}
                  </div>
                  <div className="card-body">
                    <h5 className="text-center">
                      <span style={{ marginRight: "3%" }} alt="open">
                        🟡
                      </span>
                      Réservations
                    </h5>
                    {info_hairdresser.booked_slots &&
                      info_hairdresser.booked_slots.map((a, b) => (
                        <ul className="listgroup">
                          <li
                            id={b}
                            style={{
                              backgroundColor: "#deb630",
                              color: "white",
                              textAlign: "center",
                              marginBottom: "5px",
                            }}
                            className="list-group-item list-group-item-action"
                          >
                            {a}
                          </li>
                        </ul>
                      ))}
                  </div>
                </div>
                {/* <div className="col-lg-4">
                  <div className="card-body">
                    <h5 className="text-center">
                      <span style={{ marginRight: "3%" }} alt="open">
                        🔵
                      </span>
                      Réservés
                    </h5>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlanningPro;
