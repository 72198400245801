import { GET_ALL_TOWNS, GET_ALL_DEPARTMENTS } from "../actions/types";
import { time_slot } from "../utils/dashboardFunction";

const initialState = {
  time_presta: Array.from(Array(6).keys()),
  resiliation_reason: [
    "Le prix est trop élevé",
    `J'ai souscrit à un autre service`,
    "Je ne souhaite pas répondre",
    "Autre raison",
  ],
  all_departments: [],
  all_towns: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TOWNS:
      return {
        ...state,
        all_towns: [...action.payload],
      };
    case GET_ALL_DEPARTMENTS:
      return {
        ...state,
        all_departments: [...action.payload],
      };

    default:
      return state;
  }
}
