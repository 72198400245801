import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DashboardAdmin from "../../components/admin/DashboardAdmin";
import Sidebar from "../../components/layout/Sidebar";

import {
  getHairdressInfo,
  getAllHairdress,
} from "../../actions/hairdressActions";

import { getAllAppointmentClient } from "../../actions/appointmentActions";

import { logoutClient } from "../../actions/authActions";

const DashboardAdminPage = ({
  clientObj,
  getHairdressInfo,
  info_hairdresser,
  getAllHairdress,
  all_users,
  logoutClient,
  getAllAppointmentClient,
  all_appointment_client,
}) => {
  useEffect(() => {
    getHairdressInfo();
  }, [getHairdressInfo]);

  useEffect(() => {
    getAllHairdress();
  }, [getAllHairdress]);

  useEffect(() => {
    getAllAppointmentClient();
  }, [getAllAppointmentClient]);

  // Logout
  const handleLogOut = () => {
    logoutClient();
  };

  return (
    <>
      <Sidebar clientObj={clientObj} onLogOut={handleLogOut} />
      <DashboardAdmin
        info_hairdresser={info_hairdresser}
        all_users={all_users}
        all_appointment_client={all_appointment_client}
      />
    </>
  );
};

DashboardAdminPage.propTypes = {
  getHairdressInfo: PropTypes.func.isRequired,
  logoutClient: PropTypes.func.isRequired,
  getAllHairdress: PropTypes.func.isRequired,
  getAllAppointmentClient: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  errors: state.errors,
  clientObj: state.auth.clientObj,
  info_hairdresser: state.hairdress.info_hairdresser,
  all_users: state.hairdress.all_users,
  all_appointment_client: state.appointment.all_appointment_client,
});

export default connect(mapStateToProps, {
  getHairdressInfo,
  logoutClient,
  getAllHairdress,
  getAllAppointmentClient,
})(DashboardAdminPage);
