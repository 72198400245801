import React, { useEffect } from "react";
import isEmpty from "is-empty";
import { currencyFormat_other } from "../../utils/dashboardFunction";

import FeatherIcon from "feather-icons-react/build/FeatherIcon";

// import CarouselPage from "../../containers/layout/CarouselPage";

const ServicesPro = ({
  info_hairdresser,
  all_prestations,
  prestation,
  time_presta,
  onChange,
  onSubmit,
  onAddPresta,
  addPresta,
}) => {
  useEffect(() => {
    document.body.classList.add("provider-body");
    return () => document.body.classList.remove("provider-body");
  }, []);

  const { presta_price, presta_item, presta_item_selection, presta_duration } =
    prestation;

  const isEnabled = isEmpty(presta_item) || isEmpty(presta_duration);

  const prestas = info_hairdresser && info_hairdresser.presta;

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h6>Mes services</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h6 className="subhead-title">Prestations proposées</h6>
            <fieldset id="first-field">
              <div className="addservice-info">
                {prestas &&
                  prestas.map((a, index) => (
                    <div className="row service-cont" key={index}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="col-form-label">
                            Prestation{" "}
                            {a.isActive ? (
                              <i
                                className="far fa-trash-alt"
                                alt="presta_active"
                              >
                                ☀️
                              </i>
                            ) : (
                              <i
                                className="far fa-trash-alt"
                                alt="presta_inactive"
                              >
                                💤
                              </i>
                            )}
                          </label>

                          <input
                            disabled={true}
                            className="form-control"
                            placeholder={a.presta_item}
                            type="texte"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="col-form-label">Prix</label>

                          <input
                            className="form-control"
                            name="presta_price_update"
                            placeholder={currencyFormat_other(a.presta_price)}
                            type="number"
                            disabled={a.isActive ? false : true}
                            onChange={(e) =>
                              onChange(e, "update_price", a.presta_item, a.id)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex">
                          <div className="form-group w-100">
                            <label className="col-form-label">Durée</label>
                            <select
                              disabled={a.isActive ? false : true}
                              onChange={(e) =>
                                onChange(
                                  e,
                                  "update_duration",
                                  a.presta_item,
                                  a.id
                                )
                              }
                              name="presta_duration_update"
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option style={{ textAlign: "center" }} selected>
                                {a.presta_duration}
                                {a.presta_duration === 0 ? "heure" : "heures"}
                              </option>
                              {time_presta
                                .filter((b) => b + 1 !== a.presta_duration)
                                .map((c) => (
                                  <option
                                    value={c + 1}
                                    style={{ textAlign: "center" }}
                                  >
                                    {c + 1} {c === 0 ? "heure" : "heures"}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="col-form-label">
                            {presta_item_selection === a.presta_item
                              ? "Valider"
                              : "Active/Inative"}
                          </label>

                          {presta_item_selection === a.presta_item ? (
                            <div className="form-group align-content-center">
                              <label className="col-form-label">&nbsp;</label>
                              <button
                                className="btn btn-success"
                                style={{ width: "100px" }}
                                id="submit"
                                type="submit"
                                disabled={a.isActive ? false : true}
                                onClick={(e) => onSubmit(e, "update", a)}
                              >
                                <span alt="validate">Valider</span>
                              </button>
                            </div>
                          ) : (
                            <div className="form-group">
                              <label className="col-form-label">&nbsp;</label>
                              <button
                                style={{ width: "100px" }}
                                onClick={(e) =>
                                  onSubmit(
                                    e,
                                    "active",
                                    a.presta_item,
                                    !a.isActive
                                  )
                                }
                                className={
                                  a.isActive
                                    ? "btn btn-md btn-danger-light"
                                    : "btn btn-md btn-light-success"
                                }
                              >
                                {a.isActive ? "Désactiver" : "Activer"}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                {addPresta ? (
                  <div className="row service-cont">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="col-form-label">Prestation</label>

                        <select
                          onChange={onChange}
                          name="presta_item"
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option style={{ textAlign: "center" }} selected>
                            * Prestation *
                          </option>
                          {all_prestations.map((b, c) => (
                            <option
                              value={c.id}
                              style={{ textAlign: "center" }}
                            >
                              {b.prestation}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="col-form-label">Prix</label>

                        <input
                          className="form-control"
                          style={{
                            alignContent: "center",
                          }}
                          name="presta_price"
                          type="number"
                          placeholder="40 €"
                          value={presta_price}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex">
                        <div className="form-group w-100">
                          <label className="col-form-label">Durée</label>
                          <select
                            onChange={onChange}
                            name="presta_duration"
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option style={{ textAlign: "center" }} selected>
                              * Durée *
                            </option>
                            {time_presta.map((b) => (
                              <option
                                value={b + 1}
                                style={{ textAlign: "center" }}
                              >
                                {b + 1} {b === 0 ? "heure" : "heures"}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="col-form-label">Valider</label>

                        <div className="form-group align-content-center">
                          <label className="col-form-label">&nbsp;</label>
                          <button
                            className="btn btn-primary"
                            style={{ width: "100px" }}
                            id="submit"
                            type="submit"
                            disabled={isEnabled ? true : false}
                            onClick={(e) => onSubmit(e, "add")}
                          >
                            <span alt="validate">Valider</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
              <button
                className="btn btn-outline-warning"
                onClick={onAddPresta}
                disabled={
                  isEmpty(all_prestations) ? true : addPresta ? true : false
                }
                // onClick={(e) => onSubmit(e, "add")}
              >
                <FeatherIcon icon={"plus-circle"} /> Ajouter une prestation
              </button>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServicesPro;
