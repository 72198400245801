import {
  GET_ALL_APPOINTMENT_CLIENT,
  TOGGLE_APPOINTMENT,
} from "../actions/types";

const initialState = {
  all_appointment_client: [],
  appointment_toggle: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_APPOINTMENT_CLIENT:
      return {
        ...state,
        all_appointment_client: [...action.payload],
      };
    case TOGGLE_APPOINTMENT:
      return {
        ...state,
        appointment_toggle: !state.appointment_toggle,
      };

    default:
      return state;
  }
}
