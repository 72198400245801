import React, { useEffect } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import Calendar from "react-calendar";
import "../clients/Calendar.css";
import moment from "moment";
import "moment/locale/fr";
import {
  currencyFormat_other,
  date_format,
} from "../../utils/dashboardFunction";
import isEmpty from "is-empty";

const Hairdress = ({
  info_hairdress,
  onCalendarChange,
  choice,
  // Booking
  steps,
  stepNumber,
  onClickStep,
  onChangeBooking,
  onCancelBooking,
  onBlur,
  onSubmitBooking,
}) => {
  useEffect(() => {
    document.body.classList.add("provider-body");
    return () => document.body.classList.remove("provider-body");
  }, []);

  const last_day = moment()
    .add(1, "months")
    .endOf("month")
    .format("MM-DD-YYYY");

  const {
    date,
    slot_selected,
    prestation_selected,
    client_email,
    client_name,
    errors,
  } = choice;

  // STEPPER
  // const StepStyleDTO = {
  //   activeBgColor: "#e8a11c",
  //   completedBgColor: "#90baca",
  //   activeTextColor: "#000000",
  // };
  // const ConnectorStyleProps = {
  //   activeColor: "#90baca",
  //   completeColor: "#90baca",
  // };

  function step_display(step) {
    switch (step) {
      case 0:
        return (
          <div className="provider-info">
            <div className="row">
              {/* DISPLAY CALENDAR */}
              <div className="col-lg-6 col-md-12">
                <div className="provide-info">
                  <h6>
                    {" "}
                    <span style={{ marginRight: "3%" }}>
                      <FeatherIcon icon={"calendar"} />
                    </span>
                    Mes disponibilités
                  </h6>
                  <br />
                  {isEmpty(info_hairdress) ? (
                    <Calendar
                      minDate={new Date()}
                      maxDate={new Date(last_day)}
                      onChange={onCalendarChange}
                      locale="fr-FR"
                    />
                  ) : (
                    <Calendar
                      minDate={new Date()}
                      maxDate={new Date(last_day)}
                      onChange={onCalendarChange}
                      locale="fr-FR"
                      tileClassName={({ date, view }) => {
                        if (
                          info_hairdress.open_dates &&
                          info_hairdress.open_dates.includes(
                            moment(date).format("MM/DD/YYYY")
                          )
                        ) {
                          return "open_slot";
                        }
                      }}
                      tileDisabled={({ date }) =>
                        info_hairdress.open_dates &&
                        !info_hairdress.open_dates.includes(
                          moment(date).format("MM/DD/YYYY")
                        )
                      }
                    />
                  )}
                </div>
              </div>
              {/* DISPLAY AVAILABLE SLOTS */}
              <div className="col-lg-6 col-md-12">
                <div className="provide-info">
                  <h6 className="text-center">
                    <span style={{ marginRight: "3%" }} alt="open">
                      🟢
                    </span>
                    Créneaux disponibles
                  </h6>
                  <br />
                  {info_hairdress.open_slots &&
                    info_hairdress.open_slots.map((a, b) => (
                      <ul className="listgroup">
                        <li
                          id={b}
                          onClick={(e) =>
                            onChangeBooking(e, "slot", [date_format(date), a])
                          }
                          style={{
                            backgroundColor:
                              slot_selected &&
                              date_format(date) === slot_selected[0] &&
                              a === slot_selected[1]
                                ? "rgb(169, 226, 169)"
                                : "#7072b3",
                            color: "black",
                            textAlign: "center",
                            marginBottom: "5px",
                          }}
                          className="list-group-item list-group-item-action"
                        >
                          {a}
                        </li>
                      </ul>
                    ))}
                </div>
              </div>
              {/* DISPLAY BUTTON */}
              <div className="text-center col-lg-12 col-md-12 mt-5">
                {isEmpty(slot_selected) ? (
                  <button className="btn btn-outline-success" id="step">
                    Suivant
                  </button>
                ) : (
                  <button
                    className="btn btn-success text-center"
                    onClick={onClickStep}
                    id="step"
                  >
                    Suivant
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="provider-info">
            <div className="row">
              {/* DISPLAY SERVICE */}
              <div className="col-lg-12 col-md-12">
                <div className="provide-info">
                  <h6 className="text-center">
                    <span style={{ marginRight: "3%" }} alt="open">
                      🪮
                    </span>
                    Prestations proposées
                  </h6>
                  <br />
                  <p>
                    {info_hairdress.presta &&
                      info_hairdress.presta.map((a, b) => {
                        return (
                          <ul
                            onClick={(e) => onChangeBooking(e, "prestation", a)}
                            style={{ color: "black" }}
                            key={b}
                          >
                            <li
                              style={{
                                listStyle: "inside",
                                backgroundColor:
                                  prestation_selected.presta_item ===
                                  a.presta_item
                                    ? "rgb(169, 226, 169)"
                                    : "#7072b3",
                              }}
                            >
                              <b>{a.presta_item}</b> :{" "}
                              {currencyFormat_other(a.presta_price)} |
                              <span
                                // style={{ marginLeft: "10%" }}
                                alt="time"
                              >
                                ⏳
                              </span>
                              {a.presta_duration}
                              {a.presta_duration === 1 ? " heure" : " heures"}
                            </li>
                          </ul>
                        );
                      })}
                  </p>
                </div>
              </div>
              {/* DISPLAY BUTTON */}
              <div className="text-center col-lg-12 col-md-12 mt-5">
                {isEmpty(prestation_selected) ? (
                  <button className="btn btn-outline-success" id="step">
                    Suivant
                  </button>
                ) : (
                  <button
                    className="btn btn-success text-center"
                    onClick={onClickStep}
                    id="step"
                  >
                    Suivant
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="provider-info">
            <div className="row">
              {/* Synthesis */}
              <div className="col-lg-12 col-md-12">
                <div className="provide-info">
                  <h6 className="text-center">
                    <span style={{ marginRight: "3%" }} alt="basket">
                      🧺
                    </span>
                    Résumé de votre réservation
                  </h6>
                  <br />
                  <div className="card flex-fill">
                    <ul className="listgroup pb-3 pt-3">
                      <li style={{ paddingLeft: "15px", color: "black" }}>
                        <span style={{ marginRight: "10px" }} alt="calendar">
                          📅
                        </span>{" "}
                        {moment(slot_selected[0])
                          .format("dddd D MMMM yyyy")
                          .toString()}
                      </li>
                      <li
                        style={{
                          paddingTop: "5px",
                          paddingLeft: "15px",
                          color: "black",
                        }}
                      >
                        <span style={{ marginRight: "10px" }} alt="slot">
                          🕗
                        </span>
                        Rendez-vous : {slot_selected[1]}
                      </li>
                      <li
                        style={{
                          paddingTop: "5px",
                          paddingLeft: "15px",
                          color: "black",
                        }}
                      >
                        <span style={{ marginRight: "10px" }} alt="comb">
                          🪮
                        </span>
                        {prestation_selected.presta_item} :{" "}
                        {currencyFormat_other(prestation_selected.presta_price)}
                      </li>
                      <li
                        style={{
                          paddingTop: "5px",
                          paddingLeft: "15px",
                          color: "black",
                        }}
                      >
                        <span style={{ marginRight: "10px" }} alt="Home">
                          🏠
                        </span>
                        Se déplace à domicile :{" "}
                        {info_hairdress["atClient"] ? (
                          <span alt="yes">✅</span>
                        ) : (
                          <span alt="no">❌</span>
                        )}
                      </li>
                      <li
                        style={{
                          paddingTop: "5px",
                          paddingLeft: "15px",
                          color: "black",
                        }}
                      >
                        <label
                          style={{ display: "block", marginRight: "10px" }}
                          alt="comment"
                        >
                          📝 Commentaire
                        </label>
                        <textarea
                          name="client_comment"
                          placeholder="Précisez le détail de la prestation souhaitée : à domicile, un modèle précis etc."
                          onChange={(e) =>
                            onChangeBooking(e, "client_comment", e)
                          }
                          style={{
                            fontSize: "16px",
                            minHeight: "40px",
                            width: "90%",
                            display: "block",
                          }}
                          onBlur={onBlur}
                        />
                      </li>
                      <li
                        style={{
                          paddingTop: "8px",
                          paddingLeft: "15px",
                          color: "black",
                        }}
                      >
                        <label
                          style={{ display: "block", marginRight: "10px" }}
                          alt="client_name"
                        >
                          👤 Prénom
                        </label>
                        <input
                          type="text"
                          onBlur={onBlur}
                          placeholder="Votre prénom"
                          name="client_name"
                          onChange={(e) => onChangeBooking(e, "client_name", e)}
                          style={{
                            fontSize: "16px",
                            minHeight: "40px",
                            width: "90%",
                            display: "block",
                          }}
                        />
                      </li>
                      <li
                        style={{
                          paddingTop: "8px",
                          paddingLeft: "15px",
                          color: "black",
                        }}
                      >
                        <label
                          style={{ display: "block", marginRight: "10px" }}
                          alt="email"
                        >
                          💌 Email
                        </label>
                        <input
                          type="email"
                          onBlur={onBlur}
                          placeholder="email@mail.com"
                          name="client_email"
                          onChange={(e) =>
                            onChangeBooking(e, "client_email", e)
                          }
                          style={{
                            fontSize: "16px",
                            minHeight: "40px",
                            width: "90%",
                            display: "block",
                          }}
                        />
                        <p style={{ color: "red" }}>{errors.email}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* DISPLAY BUTTON */}
              <div className="row">
                <div className="text-center col-lg-6 col-md-12">
                  <button
                    className="btn btn-danger text-center"
                    onClick={onCancelBooking}
                    id="step"
                  >
                    Annuler
                  </button>
                </div>
                <div className="text-center col-lg-6 col-md-12">
                  <button
                    className="btn btn-success text-center"
                    onClick={onSubmitBooking}
                    id="step"
                    disabled={
                      stepNumber === 2
                        ? isEmpty(client_email) || isEmpty(client_name)
                          ? true
                          : isEmpty(errors.email)
                          ? false
                          : true
                        : false
                    }
                  >
                    Réserver
                  </button>
                </div>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="provider-info">
            <div className="row">
              {/* Synthesis */}
              <div className="col-lg-12 col-md-12">
                <div className="provide-info">
                  <h6 className="text-center">
                    <span style={{ marginRight: "1%" }} alt="basket">
                      📝
                    </span>
                    Merci, votre réservation est enregistrée
                  </h6>
                  <br />
                  <div className="card flex-fill">
                    Un mail de confirmation vient de vous être envoyé, il se
                    trouve peut être dans vos <b>SPAMS. </b> <br />
                    Vous pouvez à présent contacter votre expert des cheveux
                    texturés pour peaufiner les détails de votre rendez - vous
                    <br /> <br /> Un rappel vous sera envoyé un jour avant la
                    prestation. <br />
                    <br />
                    <br />
                    <span alt="heart">
                      Merci d'avoir fait confiance à Usouka ❤️
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        break;
    }
  }

  return (
    <>
      {/* Page Header */}
      <br />
      <br />
      <br />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            {isEmpty(info_hairdress) ? (
              <div className="col-md-12">
                <h2 className="breadcrumb-title mb-0">
                  Utilisateur introuvable
                </h2>
              </div>
            ) : (
              <div className="col-md-12">
                <h2 className="breadcrumb-title mb-0">
                  {info_hairdress.username && info_hairdress.username}
                </h2>
                {/* <h5>
                  {info_hairdress.rating && rating(info_hairdress.rating)}
                </h5> */}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="provider-img">
                {isEmpty(info_hairdress) ? (
                  <div />
                ) : (
                  <img
                    // src={
                    //   info_hairdress.pictures &&
                    //   info_hairdress.pictures
                    //     .filter((a) => a.isProfil)
                    //     .map((b) => b.url)
                    // }
                    src={
                      "https://res.cloudinary.com/duuamjvt3/image/upload/v1700759230/Usouka_service/usouka_no_pp.jpg"
                    }
                    className="img-fluid"
                    alt="img"
                  />
                )}
                <span>{info_hairdress.name && info_hairdress.name}</span>
              </div>
            </div>
            <div className="col-md-7">
              <div className="provider-info">
                {/* <h2>{info_hairdress.username} </h2>
                <h5>
                  {info_hairdress.rating && rating(info_hairdress.rating)}
                </h5> */}
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo dolor in reprehenderit
                  in voluptate consequat.{" "}
                </p> */}
                <div className="row">
                  {/* <div className="col-lg-6 col-md-12">
                    <div className="provide-info">
                      <h6>
                        {" "}
                        <span style={{ marginRight: "3%" }}>
                          <FeatherIcon icon={"calendar"} />
                        </span>
                        Mes disponibilités
                      </h6>
                      <br />

                      {isEmpty(info_hairdress) ? (
                        <Calendar
                          minDate={new Date()}
                          maxDate={new Date(last_day)}
                          onChange={onCalendarChange}
                          locale="fr-FR"
                        />
                      ) : (
                        <Calendar
                          minDate={new Date()}
                          maxDate={new Date(last_day)}
                          onChange={onCalendarChange}
                          locale="fr-FR"
                          tileClassName={({ date, view }) => {
                            if (
                              info_hairdress.open_dates &&
                              info_hairdress.open_dates.includes(
                                moment(date).format("MM/DD/YYYY")
                              )
                            ) {
                              return "open_slot";
                            }
                          }}
                          tileDisabled={({ date }) =>
                            info_hairdress.open_dates &&
                            !info_hairdress.open_dates.includes(
                              moment(date).format("MM/DD/YYYY")
                            )
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="provide-info">
                      <h6 className="text-center">
                        <span style={{ marginRight: "3%" }} alt="open">
                          🟢
                        </span>
                        Créneaux disponibles
                      </h6>
                      <br />
                      {info_hairdress.open_slots &&
                        info_hairdress.open_slots.map((a, b) => (
                          <ul className="listgroup">
                            <li
                              id={b}
                              style={{
                                backgroundColor: "green",
                                color: "white",
                                textAlign: "center",
                                marginBottom: "5px",
                              }}
                              className="list-group-item list-group-item-action"
                            >
                              {a}
                            </li>
                          </ul>
                        ))}
                    </div>
                  </div> */}
                  {step_display(stepNumber)}

                  {stepNumber === 0 ? (
                    <div className="col-lg-8 col-md-12 mt-5">
                      <div className="provide-box">
                        <span>
                          <FeatherIcon icon={"package"} />
                        </span>
                        <div className="provide-info">
                          <h6>Prestations proposées</h6>
                          <p>
                            {info_hairdress.presta &&
                              info_hairdress.presta.map((a, b) => {
                                return (
                                  <ul style={{ color: "black" }} key={b}>
                                    <li style={{ listStyle: "inside" }}>
                                      <b>{a.presta_item}</b> :{" "}
                                      {currencyFormat_other(a.presta_price)} |
                                      <span
                                        // style={{ marginLeft: "10%" }}
                                        alt="time"
                                      >
                                        ⏳
                                      </span>
                                      {a.presta_duration}
                                      {a.presta_duration === 1
                                        ? " heure"
                                        : " heures"}
                                    </li>
                                  </ul>
                                );
                              })}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}

                  {stepNumber === 0 ? (
                    <div className="col-lg-4 col-md-12 mt-5">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 ">
                          <div className="provide-box">
                            <span alt="home">
                              <FeatherIcon icon={"home"} />
                            </span>

                            <div className="provide-info">
                              <h6>Ville</h6>
                              <p>
                                {info_hairdress.town && info_hairdress.town} (
                                {info_hairdress.department &&
                                  info_hairdress.department}
                                )
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="provide-box">
                            <span alt="home">
                              <FeatherIcon icon={"truck"} />
                            </span>

                            <div className="provide-info">
                              <h6>Déplacements</h6>

                              {info_hairdress.presta && (
                                <ul style={{ color: "black" }}>
                                  <li style={{ listStyle: "inside" }}>
                                    A domicile :{" "}
                                    {info_hairdress["atClient"] ? (
                                      <span alt="yes">✅</span>
                                    ) : (
                                      <span alt="no">❌</span>
                                    )}
                                  </li>
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Hairdress;
