import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Footer from "../../components/layout/Footer";

const FooterPage = () => {
  return <Footer />;
};

FooterPage.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(FooterPage);
