import axios from "axios";
import {
  GET_HAIRDRESS_INFO,
  GET_HAIRDRESS_PUBLIC_INFO,
  TOGGLE_UPDATE_INFO,
  UPDATE_HAIRDRESS_SLOTS,
  UPDATE_HAIRDRESS_PRESTA,
  GET_SLOTS_AVAILABLE,
  SET_MESSAGE_SUBMIT,
  SET_MESSAGE_ERROR,
  ADD_NEW_IMAGE,
  DELETE_IMAGE,
  UPDATE_PROFIL,
  UPDATE_PROFIL_PIC,
  UPDATE_SUBSCRIPTION_DETAILS,
  UPDATE_HAIRDRESS_INFO,
  GET_ALL_HAIRDRESSERS,
} from "./types";

import { setErrors } from "./errorActions";

export const getHairdressInfo = (date_selected) => (dispatch) => {
  axios
    .post(`/api/hairdress/info`, date_selected)
    .then((res) => {
      dispatch({
        type: GET_HAIRDRESS_INFO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

export const getHairdressInfoPublic = (data) => (dispatch) => {
  axios
    .post(`/api/hairdress/public/`, data)
    .then((res) => {
      dispatch({
        type: GET_HAIRDRESS_PUBLIC_INFO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

export const getAllHairdress = (data) => (dispatch) => {
  axios
    .get(`/api/hairdress/all`, data)
    .then((res) => {
      dispatch({
        type: GET_ALL_HAIRDRESSERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

export const postUpdateSlots = (data) => (dispatch) => {
  dispatch(toggleUpdateHairdress());
  axios
    .post(`/api/hairdress/update_slots`, data)
    .then((res) => {
      dispatch({
        type: UPDATE_HAIRDRESS_SLOTS,
        payload: res.data,
      });
      dispatch(toggleUpdateHairdress());
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(toggleUpdateHairdress());
    });
};

export const postUpdatePrestations = (data) => (dispatch) => {
  dispatch(toggleUpdateHairdress());
  axios
    .post(`/api/hairdress/update_presta`, data)
    .then((res) => {
      dispatch({
        type: UPDATE_HAIRDRESS_PRESTA,
        payload: res.data,
      });
      dispatch(toggleUpdateHairdress());
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(toggleUpdateHairdress());
    });
};

export const getSlotAvailable = () => (dispatch) => {
  axios
    .get(`/api/hairdress/slots`)
    .then((res) => {
      dispatch({
        type: GET_SLOTS_AVAILABLE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

export const postNewImage = (img) => (dispatch) => {
  dispatch(toggleUpdateHairdress());
  axios
    .post("/api/hairdress/images", { data: img })
    .then((res) => {
      dispatch({
        type: ADD_NEW_IMAGE,
        payload: res.data,
      });
      dispatch(toggleUpdateHairdress());
    })
    .catch((err) => {
      dispatch(setErrors(err));
      dispatch(toggleUpdateHairdress());
    });
};

export const postProfilPic = (img) => (dispatch) => {
  dispatch(toggleUpdateHairdress());
  axios
    .post("/api/hairdress/images_pic", { data: img })
    .then((res) => {
      dispatch({
        type: UPDATE_PROFIL_PIC,
        payload: res.data,
      });
      dispatch(toggleUpdateHairdress());
    })
    .catch((err) => {
      dispatch(setErrors(err));
      dispatch(toggleUpdateHairdress());
    });
};

export const deleteImage = (img) => (dispatch) => {
  dispatch(toggleUpdateHairdress());
  axios
    .post("/api/hairdress/del_image", img)
    .then((res) => {
      dispatch({
        type: DELETE_IMAGE,
        payload: res.data,
      });
      dispatch(toggleUpdateHairdress());
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(toggleUpdateHairdress());
    });
};

export const postUpdateProfil = (data) => (dispatch) => {
  dispatch(toggleUpdateHairdress());
  axios
    .post("/api/hairdress/update_profil", data)
    .then((res) => {
      dispatch({
        type: UPDATE_PROFIL,
        payload: res.data,
      });
      dispatch(toggleUpdateHairdress());
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(toggleUpdateHairdress());
    });
};

export const postPassword = (data) => (dispatch) => {
  dispatch(toggleUpdateHairdress());
  axios
    .post("/api/hairdress/update_password", data)
    .then((res) => {
      dispatch({
        type: UPDATE_PROFIL,
        payload: res.data,
      });
      dispatch(toggleUpdateHairdress());
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(toggleUpdateHairdress());
    });
};
export const updateSubRequest = (data, history) => (dispatch) => {
  dispatch({
    type: UPDATE_SUBSCRIPTION_DETAILS,
    payload: data,
  });

  history(`/abonnements/paiement`);
};

export const updateHairdressInfo = (data) => (dispatch) => {
  dispatch(toggleUpdateHairdress());
  axios
    .post(`/api/hairdress/info/update`, { data })
    .then((res) => {
      dispatch({
        type: UPDATE_HAIRDRESS_INFO,
        payload: res.data,
      });
      dispatch(toggleUpdateHairdress());
    })
    .catch((err) => {
      dispatch(toggleUpdateHairdress());
      dispatch(setErrors(err.response.data));
    });
};
export const toggleSubmitMsg = () => {
  return {
    type: SET_MESSAGE_SUBMIT,
  };
};

export const toggleFailedMsg = () => {
  return {
    type: SET_MESSAGE_ERROR,
  };
};

export const toggleUpdateHairdress = () => {
  return {
    type: TOGGLE_UPDATE_INFO,
  };
};
