import React, { useEffect } from "react";

// import { Link } from "react-router-dom";
import isEmpty from "is-empty";
import {
  date_format,
  currencyFormat_other,
} from "../../utils/dashboardFunction";

import Calendar from "react-calendar";
import "./Calendar.css";
import moment from "moment";
import "moment/locale/fr";

const BookingPro = ({
  info_hairdresser,
  onCalendarChange,
  availability,
  onClickSlot,
}) => {
  useEffect(() => {
    document.body.classList.add("provider-body");
    return () => document.body.classList.remove("provider-body");
  }, []);
  const { date } = availability;
  const last_day = moment()
    .add(1, "months")
    .endOf("month")
    .format("MM-DD-YYYY");

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h6>Mes rendez-vous</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 d-flex flex-column">
            <h6 className="subhead-title">Calendrier</h6>
            <div className="card flex-fill">
              <div className="card-body">
                <Calendar
                  minDate={new Date()}
                  maxDate={new Date(last_day)}
                  onChange={onCalendarChange}
                  locale="fr-FR"
                  tileClassName={({ date, view }) => {
                    const formattedDate = moment(date).format("MM/DD/YYYY");

                    const isBooked =
                      info_hairdresser.booked_dates?.includes(formattedDate);

                    if (isBooked) {
                      return "booked";
                    }
                  }}
                  tileDisabled={({ date }) =>
                    info_hairdresser.booked_dates &&
                    !info_hairdresser.booked_dates.includes(
                      moment(date).format("MM/DD/YYYY")
                    )
                  }
                />

                <div className="calendar-legend pt-2">
                  <div className="legend-item">
                    <span
                      className="legend-color"
                      style={{ backgroundColor: "#deb630" }}
                    ></span>
                    <span className="legend-text">Rendez-vous client</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-8 d-flex flex-column"
            style={{ maxHeight: "450px", overflowY: "auto" }}
          >
            <h6 className="subhead-title">Mes rendez - vous client</h6>
            <div className="card flex-fill">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Prénom</th>
                    <th scope="col">Email</th>
                    <th scope="col">Prestation</th>
                    <th scope="col">Prix €</th>
                    <th scope="col">Commentaire</th>
                  </tr>
                </thead>
                <tbody>
                  {isEmpty(info_hairdresser.booked_dates_details) ? (
                    <div />
                  ) : (
                    info_hairdresser.booked_dates_details.map(
                      (value, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <h2 className="table-avatar">
                                <span>{value.name}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{value.email}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{value.presta}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{currencyFormat_other(value.price)}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{value.comment}</span>
                              </h2>
                            </td>
                          </tr>
                        );
                      }
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="col-lg-10 d-flex flex-column"
            style={{ maxHeight: "450px", overflowY: "auto" }}
          >
            <h6 className="subhead-title">Tous mes rendez-vous</h6>
            <div className="card flex-fill">
              <h6 className="pt-3">Prochains rendez - vous</h6>
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date</th>
                    <th scope="col">Prénom</th>
                    <th scope="col">Email</th>
                    <th scope="col">Prestation</th>
                    <th scope="col">Prix €</th>
                    <th scope="col">Commentaire</th>
                  </tr>
                </thead>
                {console.log(moment().format("DD/MM/YYYY"))}
                <tbody>
                  {isEmpty(info_hairdresser.all_booked_dates_details) ? (
                    <div />
                  ) : (
                    info_hairdresser.all_booked_dates_details
                      .filter((a) =>
                        moment().isSameOrBefore(moment(a.date, "DD/MM/YYYY"))
                      )
                      .map((value, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>

                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{value.date}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar">
                                <span>{value.name}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{value.email}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{value.presta}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{currencyFormat_other(value.price)}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{value.comment}</span>
                              </h2>
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
              <h6 className="pt-3">Rendez - vous passés </h6>
              <table className="table mb-0">
                <tbody>
                  {isEmpty(info_hairdresser.all_booked_dates_details) ? (
                    <div />
                  ) : (
                    info_hairdresser.all_booked_dates_details
                      .filter((a) =>
                        moment().isAfter(moment(a.date, "DD/MM/YYYY"))
                      )
                      .map((value, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>

                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{value.date}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar">
                                <span>{value.name}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{value.email}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{value.presta}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{currencyFormat_other(value.price)}</span>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar table-user">
                                <span>{value.comment}</span>
                              </h2>
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingPro;
