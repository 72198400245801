import React from "react";
import { Link } from "react-router-dom";

const TermsCoPage = () => {
  return (
    <>
      <div>
        <div className="bg-img">
          {/* <img src={work_bg_03} alt="img" className="bgimg1" /> */}
          {/* <img src={work_bg_03} alt="img" className="bgimg2" /> */}
        </div>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">
                  Conditions générales d'utilisation
                  <h4>En vigueur au 27/11/2023</h4>
                </h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* /Breadcrumb */}
        <div className="content">
          <div className="container">
            <div className="row">
              {/* Terms & Conditions */}
              <div className="col-md-12">
                <div className="terms-content">
                  <p>
                    Les présentes conditions générales d'utilisation (dites «
                    CGU ») ont pour objet l'encadrement juridique des modalités
                    de mise à disposition du site et des services par{" "}
                    <b>Usouka</b> et de définir les conditions d’accès et
                    d’utilisation des services par <b>« l'Utilisateur ».</b>
                  </p>

                  <p>
                    Toute inscription ou utilisation du site implique
                    l'acceptation sans aucune réserve ni restriction des
                    présentes CGU par l’utilisateur. Lors de l'inscription sur
                    le site via le Formulaire d’inscription, chaque utilisateur
                    accepte expressément les présentes CGU en cochant la case
                    précédant le texte suivant :
                    <b>
                      « Je reconnais avoir lu et compris les CGU et je les
                      accepte »
                    </b>
                    . En cas de non-acceptation des CGU stipulées dans le
                    présent contrat, l'Utilisateur se doit de renoncer à l'accès
                    des services proposés par le site. <b>www.usouka.com</b> se
                    réserve le droit de modifier unilatéralement et à tout
                    moment le contenu des présentes CGU.
                  </p>

                  <h1>ARTICLE 1 : LES MENTIONS LEGALES</h1>

                  <p>
                    L’édition et la direction de la publication du site
                    www.usouka.com est assurée par Nadia A., domicilié Paris
                    (15ème).Adresse e-mail contact@usouka.com.
                  </p>
                  <p>
                    L'hébergeur du site www.usouka.com est la société Heroku.
                  </p>

                  <h1>ARTICLE 2 : ACCES AU SITE</h1>
                  <p>
                    Le site www.usouka.com permet à l'Utilisateur un accès
                    gratuit aux services suivants :faciliter la planification de
                    rendez-vous en ligne pour les professionnels capillaires,
                    tels que les coiffeurs, les stylistes ou d'autres experts en
                    soins capillaires. Les utilisateurs peuvent prendre des
                    rendez-vous à leur convenance et gérer leurs horaires de
                    manière simple et pratique. Le site est accessible
                    gratuitement en tout lieu à tout Utilisateur ayant un accès
                    à Internet. Tous les frais supportés par l'Utilisateur pour
                    accéder au service (matériel informatique, logiciels,
                    connexion Internet, etc.) sont à sa charge.
                  </p>

                  <p>
                    L’Utilisateur non membre n'a pas accès aux services
                    réservés. Pour cela, il doit s’inscrire en remplissant le
                    formulaire. En acceptant de s’inscrire aux services
                    réservés, l’Utilisateur membre s’engage à fournir des
                    informations sincères et exactes concernant son état civil
                    et ses coordonnées, notamment son adresse email. Pour
                    accéder aux services, l’Utilisateur doit ensuite
                    s'identifier à l'aide de son identifiant et de son mot de
                    passe qui lui seront communiqués après son inscription. Tout
                    Utilisateur membre régulièrement inscrit pourra également
                    solliciter sa désinscription en se rendant à la page dédiée
                    sur son espace personnel. Celle-ci sera effective dans un
                    délai raisonnable. Tout événement dû à un cas de force
                    majeure ayant pour conséquence un dysfonctionnement du site
                    ou serveur et sous réserve de toute interruption ou
                    modification en cas de maintenance, n'engage pas la
                    responsabilité de www.usouka.com. Dans ces cas,
                    l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur
                    de toute interruption ou suspension de service, même sans
                    préavis. L'Utilisateur a la possibilité de contacter le site
                    par messagerie électronique à l’adresse email de l’éditeur
                    communiqué à l’<b>ARTICLE 1.</b>
                  </p>

                  <h1>ARTICLE 3 : COLLECTE DES DONNEES</h1>

                  <p>
                    Le site assure à l'Utilisateur une collecte et un traitement
                    d'informations personnelles dans le respect de la vie privée
                    conformément à la loi n°78-17 du 6 janvier 1978 relative à
                    l'informatique, aux fichiers et aux libertés.
                  </p>

                  <p>
                    En vertu de la loi Informatique et Libertés, en date du 6
                    janvier 1978, l'Utilisateur dispose d'un droit d'accès, de
                    rectification, de suppression et d'opposition de ses données
                    personnelles. L'Utilisateur exerce ce droit : par mail à
                    l'adresse email contact@usouka.com
                  </p>

                  <h1>ARTICLE 4 : PROPRIETE INTELLECTUELLE</h1>

                  <p>
                    Les marques, logos, signes ainsi que tous les contenus du
                    site (textes, images, son…) font l'objet d'une protection
                    par le Code de la propriété intellectuelle et plus
                    particulièrement par le droit d'auteur. La marque Usouka est
                    une marque déposée.Toute représentation et/ou reproduction
                    et/ou exploitation partielle ou totale de cette marque, de
                    quelque nature que ce soit, est totalement prohibée.
                    L'Utilisateur doit solliciter l'autorisation préalable du
                    site pour toute reproduction, publication, copie des
                    différents contenus. Il s'engage à une utilisation des
                    contenus du site dans un cadre strictement privé, toute
                    utilisation à des fins commerciales et publicitaires est
                    strictement interdite. Toute représentation totale ou
                    partielle de ce site par quelque procédé que ce soit, sans
                    l’autorisation expresse de l’exploitant du site Internet
                    constituerait une contrefaçon sanctionnée par l’article L
                    335-2 et suivants du Code de la propriété intellectuelle. Il
                    est rappelé conformément à l’article L122-5 du Code de
                    propriété intellectuelle que l’Utilisateur qui reproduit,
                    copie ou publie le contenu protégé doit citer l’auteur et sa
                    source.
                  </p>

                  <h1>ARTICLE 5 : RESPONSABILITE</h1>

                  <p>
                    Les sources des informations diffusées sur le site
                    www.usouka.com sont réputées fiables mais le site ne
                    garantit pas qu’il soit exempt de défauts, d’erreurs ou
                    d’omissions.
                  </p>

                  <p>
                    Les informations communiquées sont présentées à titre
                    indicatif et général sans valeur contractuelle. Malgré des
                    mises à jour régulières, le site www.usouka.com ne peut être
                    tenu responsable de la modification des dispositions
                    administratives et juridiques survenant après la
                    publication. De même, le site ne peut être tenue responsable
                    de l’utilisation et de l’interprétation de l’information
                    contenue dans ce site.
                  </p>

                  <p>
                    L'Utilisateur s'assure de garder son mot de passe secret.
                    Toute divulgation du mot de passe, quelle que soit sa forme,
                    est interdite. Il assume les risques liés à l'utilisation de
                    son identifiant et mot de passe. Le site décline toute
                    responsabilité. Le site www.usouka.com ne peut être tenu
                    pour responsable d’éventuels virus qui pourraient infecter
                    l’ordinateur ou tout matériel informatique de l’Internaute,
                    suite à une utilisation, à l’accès, ou au téléchargement
                    provenant de ce site.
                  </p>
                  <p>
                    La responsabilité du site ne peut être engagée en cas de
                    force majeure ou du fait imprévisible et insurmontable d'un
                    tiers.
                  </p>

                  <h1>ARTICLE 6 : LIENS HYPERTEXTES</h1>
                  <p>
                    Des liens hypertextes peuvent être présents sur le site.
                    L’Utilisateur est informé qu’en cliquant sur ces liens, il
                    sortira du site www.usouka.com. Ce dernier n’a pas de
                    contrôle sur les pages web sur lesquelles aboutissent ces
                    liens et ne saurait, en aucun cas, être responsable de leur
                    contenu.
                  </p>

                  <h1>ARTICLE 7 : COOKIES</h1>
                  <p>
                    L’Utilisateur est informé que lors de ses visites sur le
                    site, un cookie peut s’installer automatiquement sur son
                    logiciel de navigation. Les cookies sont de petits fichiers
                    stockés temporairement sur le disque dur de l’ordinateur de
                    l’Utilisateur par votre navigateur et qui sont nécessaires à
                    l’utilisation du site www.usouka.com. Les cookies ne
                    contiennent pas d’information personnelle et ne peuvent pas
                    être utilisés pour identifier quelqu’un. Un cookie contient
                    un identifiant unique, généré aléatoirement et donc anonyme.
                    Certains cookies expirent à la fin de la visite de
                    l’Utilisateur, d’autres restent.
                  </p>
                  <p>
                    L’information contenue dans les cookies est utilisée pour
                    améliorer le site www.usouka.com. En naviguant sur le site,
                    L’Utilisateur les accepte. L’Utilisateur doit toutefois
                    donner son consentement quant à l’utilisation de certains
                    cookies.
                  </p>
                  <p>
                    A défaut d’acceptation, l’Utilisateur est informé que
                    certaines fonctionnalités ou pages risquent de lui être
                    refusées. L’Utilisateur pourra désactiver ces cookies par
                    l’intermédiaire des paramètres figurant au sein de son
                    logiciel de navigation.
                  </p>

                  <h1>ARTICLE 8 : PUBLICATION PAR L’UTILISATEUR</h1>
                  <p>
                    Le site permet aux membres de publier les contenus suivants
                    : disponibilités et prestations de service. Dans ses
                    publications, le membre s’engage à respecter les règles de
                    la Netiquette (règles de bonne conduite de l’internet) et
                    les règles de droit en vigueur. Le site peut exercer une
                    modération sur les publications et se réserve le droit de
                    refuser leur mise en ligne, sans avoir à s’en justifier
                    auprès du membre.
                  </p>
                  <p>
                    Le membre reste titulaire de l’intégralité de ses droits de
                    propriété intellectuelle. Mais en publiant une publication
                    sur le site, il cède à la société éditrice le droit non
                    exclusif et gratuit de représenter, reproduire, adapter,
                    modifier, diffuser et distribuer sa publication, directement
                    ou par un tiers autorisé, dans le monde entier, sur tout
                    support (numérique ou physique), pour la durée de la
                    propriété intellectuelle. Le Membre cède notamment le droit
                    d'utiliser sa publication sur internet et sur les réseaux de
                    téléphonie mobile. La société éditrice s'engage à faire
                    figurer le nom du membre à proximité de chaque utilisation
                    de sa publication.
                  </p>
                  <p>
                    Tout contenu mis en ligne par l'Utilisateur est de sa seule
                    responsabilité. L'Utilisateur s'engage à ne pas mettre en
                    ligne de contenus pouvant porter atteinte aux intérêts de
                    tierces personnes. Tout recours en justice engagé par un
                    tiers lésé contre le site sera pris en charge par
                    l'Utilisateur. Le contenu de l'Utilisateur peut être à tout
                    moment et pour n'importe quelle raison supprimé ou modifié
                    par le site, sans préavis.
                  </p>

                  <h1>
                    ARTICLE 9 : DROIT APPLICABLE ET JURIDICTION COMPETENTE
                  </h1>
                  <p>
                    La législation française s'applique au présent contrat. En
                    cas d'absence de résolution amiable d'un litige né entre les
                    parties, les tribunaux français seront seuls compétents pour
                    en connaître. Pour toute question relative à l’application
                    des présentes CGU, vous pouvez joindre l’éditeur aux
                    coordonnées inscrites à l’<b>ARTICLE 1</b>.
                  </p>
                </div>
              </div>
              {/* /Terms & Conditions */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TermsCoPage;
