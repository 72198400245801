import React from "react";

const Footer = () => {
  return (
    <>
      {/* Footer */}
      <footer className="footer">
        {/* Footer Top */}
        <div className="footer-bottom">
          <div className="container">
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="copyright-text">
                    <p className="mb-0">© 2023. Tous droits réservés. Usouka</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="payment-image"></div>
                </div>
                <div className="col-md-4">
                  <div className="copyright-menu">
                    <ul className="policy-menu">
                      {/* <li>
                        <a href="privacy-policy.html">Privacy Policy</a>
                      </li> */}
                      <li>
                        <a href="/terms_conditions">Termes &amp; Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
