import {
  GET_HAIRDRESS_INFO,
  GET_SLOTS_AVAILABLE,
  TOGGLE_UPDATE_INFO,
  GET_HAIRDRESS_PUBLIC_INFO,
  UPDATE_SUBSCRIPTION_DETAILS,
  GET_ALL_HAIRDRESSERS,
} from "../actions/types";

const initialState = {
  info_hairdresser: {},
  info_public_hairdresser: {},
  bool_update_info: false,
  slots_available: [],
  haidresser_sub_request: {},
  all_users: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_HAIRDRESSERS:
      return {
        ...state,
        all_users: { ...action.payload },
      };
    case UPDATE_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        haidresser_sub_request: { ...action.payload },
      };
    case GET_HAIRDRESS_PUBLIC_INFO:
      return {
        ...state,
        info_public_hairdresser: { ...action.payload },
      };

    case GET_SLOTS_AVAILABLE:
      return {
        ...state,
        slots_available: [...action.payload],
      };
    case GET_HAIRDRESS_INFO:
      return {
        ...state,
        info_hairdresser: { ...action.payload },
      };
    case TOGGLE_UPDATE_INFO:
      return {
        ...state,
        bool_update_info: !state.bool_update_info,
      };
    default:
      return state;
  }
}
