import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ProfilPro from "../../components/clients/ProfilPro";
import Sidebar from "../../components/layout/Sidebar";
import Validate from "../../utils/form/Validate";

import { getAllDepartments, getAllTowns } from "../../actions/dataActions";
import {
  updateHairdressInfo,
  getHairdressInfo,
} from "../../actions/hairdressActions";
import {
  checkregisterHairdress,
  logoutClient,
} from "../../actions/authActions";

const ProfilProPage = ({
  getHairdressInfo,
  info_hairdresser,
  logoutClient,
  clientObj,
  bool_update_info,
  getAllDepartments,
  all_departments,
  getAllTowns,
  all_towns,
  checkregisterHairdress,
  updateHairdressInfo,
  errors,
}) => {
  const [customer, setCustomer] = useState({
    name: "",
    surname: "",
    username: "",
    department_id: "01",
    town_id: "01",
    atClient: "",
    errors: {},
  });

  const [displaySubmit, setDisplaySubmit] = useState(false);

  useEffect(() => {
    getHairdressInfo();
  }, [bool_update_info]);

  useEffect(() => {
    if (info_hairdresser) {
      setCustomer({
        ...customer,
        department_id: info_hairdresser.department_id || "01",
        town_id: info_hairdresser.town_id || "01",
      });
    }
  }, [info_hairdresser]);

  useEffect(() => {
    getAllDepartments();
  }, [getAllDepartments]);

  useEffect(() => {
    getAllTowns(customer.department_id);
  }, [customer.department_id]);

  useEffect(() => {
    setCustomer({ ...customer, errors: { ...errors } });
  }, [errors]);

  // Prestations
  const handleChange = (e, type, id) => {
    // e.preventDefault();
    const { name, value, maxLength, checked } = e.target;
    setDisplaySubmit(true);

    switch (name) {
      case "phone_number":
        setCustomer({
          ...customer,
          [name]: value.slice(0, maxLength),
        });

        break;
      case "atClient":
        setCustomer({
          ...customer,
          [name]: checked,
        });
        break;

      default:
        setCustomer({ ...customer, [name]: value });
        break;
    }
  };

  const handleOnBlur = (e) => {
    const { name, value } = e.target;

    name == "email"
      ? checkregisterHairdress({ name, value, type: "email" })
      : console.log("OK");

    name == "username"
      ? checkregisterHairdress({ name, value, type: "username" })
      : console.log("OK");

    const error = { ...customer.errors, ...Validate(name, value).errors };
    setCustomer({ ...customer, errors: { ...error } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, surname, username, department_id, town_id, atClient } =
      customer;

    const filtered_customer = Object.fromEntries(
      Object.entries({
        name,
        surname,
        username,
        department_id,
        town_id,
        atClient,
      }).filter(([_, value]) => value !== "")
    );

    updateHairdressInfo(filtered_customer);
  };

  // Logout
  const handleLogOut = () => {
    logoutClient();
  };

  return (
    <>
      <Sidebar clientObj={clientObj} onLogOut={handleLogOut} />
      <ProfilPro
        info_hairdresser={info_hairdresser}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onBlur={handleOnBlur}
        customer={customer}
        all_departments={all_departments}
        all_towns={all_towns}
        displaySubmit={displaySubmit}
      />
    </>
  );
};

ProfilProPage.propTypes = {
  errors: PropTypes.object.isRequired,
  getHairdressInfo: PropTypes.func.isRequired,
  logoutClient: PropTypes.func.isRequired,
  getAllDepartments: PropTypes.func.isRequired,
  getAllTowns: PropTypes.func.isRequired,
  checkregisterHairdress: PropTypes.func.isRequired,
  updateHairdressInfo: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  errors: state.errors,
  info_hairdresser: state.hairdress.info_hairdresser,
  bool_update_info: state.hairdress.bool_update_info,
  clientObj: state.auth.clientObj,
  all_departments: state.data.all_departments,
  all_towns: state.data.all_towns,
});

export default connect(mapStateToProps, {
  getHairdressInfo,
  logoutClient,
  getAllDepartments,
  getAllTowns,
  checkregisterHairdress,
  updateHairdressInfo,
})(ProfilProPage);
