import React, { useEffect } from "react";
import { connect } from "react-redux";

import AOS from "aos";
import "aos/dist/aos.css";

import Home from "../../components/layout/Home";

const HomePage = ({ clientObj }) => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return <Home />;
};

HomePage.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(HomePage);
