import React from "react";
import { Link } from "react-router-dom";

const Login = ({ isPro, client, onSubmit, onChange, onBlur }) => {
  const { email, password, errors } = client;
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header text-center">
                  <h3>Connexion {isPro ? "Professionnel" : "Client"}</h3>
                </div>
                {/* Login Form */}
                <form action="/customer-dashboard">
                  <div className="log-form">
                    <div className="form-group">
                      <label className="col-form-label">E-mail</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="exemple@email.com"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={email}
                        name="email"
                      />
                      <p style={{ color: "red" }}>{errors.email}</p>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label d-block">
                        Mot de passe
                      </label>
                      <div className="pass-group">
                        <input
                          type="password"
                          className="form-control pass-input"
                          placeholder="*************"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={password}
                          name="password"
                        />
                        <p style={{ color: "red" }}>{errors.password}</p>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="text-md-end">
                        <Link className="forgot-link" to="/password-recovery">
                          Forgot password?
                        </Link>
                      </div>
                    </div> */}
                  </div>
                  <button
                    className="btn btn-primary w-100 login-btn"
                    onClick={onSubmit}
                  >
                    Connexion
                  </button>

                  <p className="no-acc">
                    Vous n'avez pas encore de compte ?{" "}
                    <Link
                      to={isPro ? "/inscription/pro" : "/inscription/client"}
                    >
                      S'enregistrer
                    </Link>
                  </p>
                </form>
                {/* /Login Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
