import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ServicesPro from "../../components/clients/ServicesPro";
import Sidebar from "../../components/layout/Sidebar";

import Validate from "../../utils/form/Validate";

import {
  getHairdressInfo,
  postUpdatePrestations,
} from "../../actions/hairdressActions";
import { logoutClient } from "../../actions/authActions";
import { getAllPrestationsClient } from "../../actions/prestationActions";

const ServicesProPage = ({
  getHairdressInfo,
  info_hairdresser,
  logoutClient,
  clientObj,
  getAllPrestationsClient,
  all_prestations_client,
  postUpdatePrestations,
  bool_update_info,
  time_presta,
}) => {
  const [prestation, setPrestation] = useState({
    presta_id: "",
    presta_price: "",
    presta_price_update: "",
    presta_duration_update: "",
    presta_item: "",
    presta_item_selection: "",
    presta_isActive: "",
    presta_errors: { prestation: "", price: "" },
  });

  const [addPresta, setAddPresta] = useState(false);

  useEffect(() => {
    getHairdressInfo();
    getAllPrestationsClient();
  }, [bool_update_info, getAllPrestationsClient, getHairdressInfo]);

  // Prestations
  const handleChange = (e, type, item, id) => {
    e.preventDefault();
    const { name, value } = e.target;

    switch (type) {
      case "update_price":
        setPrestation((prestation) => ({
          ...prestation,
          presta_id: id,
          presta_item_selection: item,
          presta_price_update: value,
        }));

        break;

      case "update_duration":
        setPrestation((prestation) => ({
          ...prestation,
          presta_id: id,
          presta_item_selection: item,
          presta_duration_update: value,
        }));

        break;

      default:
        setPrestation((prestation) => ({ ...prestation, [name]: value }));
        break;
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = { ...prestation.errors, ...Validate(name, value).errors };
    setPrestation({ ...prestation, errors: { ...error } });
  };

  const handleSubmit = (e, presta_type, id, isActive) => {
    e.preventDefault();
    const {
      presta_id,
      presta_price,
      presta_price_update,
      presta_duration,
      presta_duration_update,
      presta_item,
      presta_item_selection,
      presta_isActive,
    } = prestation;

    let update_presta = Object.fromEntries(
      Object.entries({
        presta_id,
        presta_price,
        presta_price_update,
        presta_duration_update,
        presta_item,
        presta_item_selection,
        presta_isActive,
      }).filter(([_, value]) => value !== "")
    );

    switch (presta_type) {
      case "update":
        postUpdatePrestations({ ...update_presta, presta_type });

        break;

      case "active":
        postUpdatePrestations({ presta_item: id, presta_type, isActive });
        break;

      default:
        postUpdatePrestations({
          presta_duration,
          presta_item,
          presta_price,
          presta_type,
        });
        setAddPresta(!addPresta);
        break;
    }

    setPrestation({
      presta_id: "",
      presta_price: "",
      presta_price_update: "",
      presta_duration_update: "",
      presta_item: "",
      presta_item_selection: "",
      presta_isActive: "",
      presta_errors: { prestation: "", price: "" },
    });
  };

  const handleAddNewPresta = () => {
    setAddPresta(!addPresta);
  };

  // Logout
  const handleLogOut = () => {
    logoutClient();
  };

  return (
    <>
      <Sidebar clientObj={clientObj} onLogOut={handleLogOut} />
      <ServicesPro
        info_hairdresser={info_hairdresser}
        all_prestations={all_prestations_client}
        prestation={prestation}
        time_presta={time_presta}
        onBlur={handleBlur}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onAddPresta={handleAddNewPresta}
        addPresta={addPresta}
      />
    </>
  );
};

ServicesProPage.propTypes = {
  getHairdressInfo: PropTypes.func.isRequired,
  logoutClient: PropTypes.func.isRequired,
  postUpdatePrestations: PropTypes.func.isRequired,
  getAllPrestationsClient: PropTypes.func.isRequired,
  all_prestations_client: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  errors: state.errors,
  info_hairdresser: state.hairdress.info_hairdresser,
  bool_update_info: state.hairdress.bool_update_info,
  all_prestations_client: state.prestation.all_prestations_client,
  time_presta: state.data.time_presta,
  clientObj: state.auth.clientObj,
});

export default connect(mapStateToProps, {
  getHairdressInfo,
  logoutClient,
  getAllPrestationsClient,
  postUpdatePrestations,
})(ServicesProPage);
