import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import moment from "moment";

import PlanningPro from "../../components/clients/PlanningPro";
import Sidebar from "../../components/layout/Sidebar";

// import Validate from "../../utils/form/Validate";

import {
  getHairdressInfo,
  postUpdateSlots,
} from "../../actions/hairdressActions";
import { logoutClient } from "../../actions/authActions";

const PlanningProPage = ({
  getHairdressInfo,
  info_hairdresser,
  bool_update_info,
  logoutClient,
  postUpdateSlots,
  clientObj,
}) => {
  const [availability, setAvailability] = useState({
    date: "",
    slots: "",
  });

  useEffect(() => {
    getHairdressInfo({
      date_selected: isEmpty(availability.date)
        ? moment().format("MM/DD/yyyy")
        : moment(availability.date).format("MM/DD/yyyy"),
    });
  }, [availability.date, bool_update_info, getHairdressInfo]);

  const handleChangeCalendarDate = (e) => {
    setAvailability((availability) => ({ ...availability, date: e }));
  };

  const handleClickSlot = (e, action, object) => {
    e.preventDefault();
    postUpdateSlots({ a: object, b: action, c: object });
  };

  // Logout
  const handleLogOut = () => {
    logoutClient();
  };

  return (
    <>
      <Sidebar clientObj={clientObj} onLogOut={handleLogOut} />
      <PlanningPro
        info_hairdresser={info_hairdresser}
        onCalendarChange={handleChangeCalendarDate}
        availability={availability}
        onClickSlot={handleClickSlot}
      />
    </>
  );
};

PlanningProPage.propTypes = {
  getHairdressInfo: PropTypes.func.isRequired,
  logoutClient: PropTypes.func.isRequired,
  postUpdateSlots: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  errors: state.errors,
  info_hairdresser: state.hairdress.info_hairdresser,
  bool_update_info: state.hairdress.bool_update_info,
  clientObj: state.auth.clientObj,
});

export default connect(mapStateToProps, {
  getHairdressInfo,
  logoutClient,
  postUpdateSlots,
})(PlanningProPage);
