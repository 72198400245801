import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import moment from "moment";

import Hairdress from "../../components/layout/Hairdress";
import Validate from "./../../utils/form/Validate";

import { getHairdressInfoPublic } from "../../actions/hairdressActions";
import { postBookingPrestation } from "../../actions/clientActions";

const HairdressPage = ({
  info_public_hairdresser,
  postBookingPrestation,
  getHairdressInfoPublic,
}) => {
  const username = useLocation().pathname.slice(8);
  const [choice, setChoice] = useState({
    date: "",
    slot_selected: [],
    prestation_selected: "",
    client_email: "",
    client_name: "",
    client_comment: "",
    errors: {},
  });

  useEffect(() => {
    getHairdressInfoPublic({
      username,
      date_selected: isEmpty(choice.date)
        ? moment().format("MM/DD/yyyy")
        : moment(choice.date).format("MM/DD/yyyy"),
    });
  }, [choice.date, username, getHairdressInfoPublic]);

  const handleChangeCalendarDate = (e) => {
    setChoice((choice) => ({ ...choice, date: e }));
  };

  //STEPPER - Client Booking a service
  const steps = ["Dates & créneaux", "Prestation", "Résumé", "Réservation"];

  const [stepNumber, setStepNumber] = useState(0);
  const addStep = (e) => {
    setStepNumber(stepNumber + 1);
  };
  const handleOnChangeBooking = (e, action, object) => {
    e.preventDefault();

    switch (action) {
      case "slot":
        setChoice((choice) => ({ ...choice, slot_selected: object }));
        break;

      case "prestation":
        setChoice((choice) => ({ ...choice, prestation_selected: object }));
        break;

      case "client_name":
        setChoice((choice) => ({ ...choice, client_name: e.target.value }));
        break;
      case "client_email":
        setChoice((choice) => ({ ...choice, client_email: e.target.value }));
        break;
      case "client_comment":
        setChoice((choice) => ({ ...choice, client_comment: e.target.value }));
        break;

      default:
        break;
    }
  };
  const handleCancelBooking = (e) => {
    setChoice((choice) => ({
      date: "",
      slot_selected: [],
      prestation_selected: "",
      client_name: "",
      client_number: "",
      client_comment: "",
    }));

    setStepNumber(0);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    const error = { ...choice.errors, ...Validate(name, value).errors };
    setChoice({ ...choice, errors: { ...error } });
  };

  const handleOnSubmitBooking = (e) => {
    e.preventDefault();

    const {
      client_comment,
      client_email,
      client_name,
      slot_selected,
      prestation_selected,
    } = choice;

    postBookingPrestation({
      hairdresser: info_public_hairdresser.email,
      hairdresser_username: info_public_hairdresser.username,
      hairdresser_email: info_public_hairdresser.email,

      client_comment,
      client_email,
      client_name,
      slot_selected,
      presta: prestation_selected.presta_item,
      price: prestation_selected.presta_price,
      duration: prestation_selected.presta_duration,
    });

    setStepNumber(stepNumber + 1);
  };

  return (
    <Hairdress
      info_hairdress={info_public_hairdresser}
      onCalendarChange={handleChangeCalendarDate}
      choice={choice}
      // Booking
      steps={steps}
      stepNumber={stepNumber}
      onClickStep={addStep}
      onChangeBooking={handleOnChangeBooking}
      onCancelBooking={handleCancelBooking}
      onSubmitBooking={handleOnSubmitBooking}
      onBlur={handleBlur}
    />
  );
};

HairdressPage.propTypes = {
  getHairdressInfoPublic: PropTypes.func.isRequired,
  postBookingPrestation: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  errors: state.errors,
  info_public_hairdresser: state.hairdress.info_public_hairdresser,
});

export default connect(mapStateToProps, {
  getHairdressInfoPublic,
  postBookingPrestation,
})(HairdressPage);
