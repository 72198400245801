import {
  GET_ALL_PRODUCT,
  TOGGLE_PRODUCT,
  GET_PRODUCT_INFO,
} from "../actions/types";

const initialState = {
  all_products: [],
  product_toggle: false,
  product_sub_info: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_INFO:
      return {
        ...state,
        product_sub_info: { ...action.payload },
      };
    case GET_ALL_PRODUCT:
      return {
        ...state,
        all_products: [...action.payload],
      };
    case TOGGLE_PRODUCT:
      return {
        ...state,
        product_toggle: !state.product_toggle,
      };

    default:
      return state;
  }
}
