import {
   GET_ALL_FUNCTIONNALITY,TOGGLE_FUNCTIONNALITY
  } from "../actions/types";
  
  const initialState = {
      all_functionnalities:[],
      functionnality_toggle:false
  };
  
  export default function(state = initialState, action) {
     switch (action.type) {

      case  GET_ALL_FUNCTIONNALITY:
         return {
            ...state,
            all_functionnalities:[...action.payload]
         }      
      case TOGGLE_FUNCTIONNALITY:
         return {
            ...state,
            functionnality_toggle:!state.functionnality_toggle
         }
 
       default:
          return state;
     }
  } 