import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//explication : https://dev.to/skylerwebdev/setting-up-a-private-route-in-react-3ph0
//rest : correspond à l'ensemble des arguments présents dans l'objet Private route excepté component

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  // OLD VERSION
  return auth.isAuthenticated ? <Outlet /> : <Navigate to="/" />;

  // if (!isAuthenticated) {
  //   return <Navigate to="/" />;
  // }
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
