// STRIPE actions
export const STRIPE_CREATE_PI = "STRIPE_CREATE_PI";
export const STRIPE_PI_STATUS = "STRIPE_PI_STATUS";
export const STRIPE_CONFIRM_PAYMENT = "STRIPE_CONFIRM_PAYMENT";
export const TOGGLE_STRIPE_LOADING = "TOGGLE_STRIPE_LOADING";
export const STRIPE_ALL_PI = "STRIPE_ALL_PI";
export const STRIPE_CREATE_REFUND = "STRIPE_CREATE_REFUND";
export const STRIPE_PAYMENT_SUCCESS = "STRIPE_PAYMENT_SUCCESS";
export const STRIPE_WEBHOOK = "STRIPE_WEBHOOK";
export const STRIPE_RETRIEVE_SUB = "STRIPE_RETRIEVE_SUB";
export const STRIPE_CHECKOUT_SESSION = "STRIPE_CHECKOUT_SESSION";
export const STRIPE_CHECKOUT_LOADING = "STRIPE_CHECKOUT_LOADING";
export const STRIPE_CREATE_PI_SERVICE = "STRIPE_CREATE_PI_SERVICE";
export const STRIPE_ACCOUNT_ONBOARDING_URL = "STRIPE_ACCOUNT_ONBOARDING_URL";
export const STRIPE_ACCOUNT_INFO = "STRIPE_ACCOUNT_INFO";

//HAIRDRESS
export const GET_HAIRDRESS_INFO = "GET_HAIRDRESS_INFO";
export const GET_HAIRDRESS_PUBLIC_INFO = "GET_HAIRDRESS_PUBLIC_INFO";
export const TOGGLE_UPDATE_INFO = "TOGGLE_UPDATE_INFO";
export const UPDATE_HAIRDRESS_SLOTS = "UPDATE_HAIRDRESS_SLOTS";
export const SET_MESSAGE_ERROR = "SET_MESSAGE_ERROR";
export const SET_MESSAGE_SUBMIT = "SET_MESSAGE_SUBMIT";
export const GET_SLOTS_AVAILABLE = "GET_SLOTS_AVAILABLE";
export const UPDATE_HAIRDRESS_PRESTA = "UPDATE_HAIRDRESS_PRESTA";
export const ADD_NEW_IMAGE = "ADD_NEW_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const UPDATE_PROFIL = "UPDATE_PROFIL";
export const UPDATE_PROFIL_PIC = "UPDATE_PROFIL_PIC";
export const UPDATE_SUBSCRIPTION_DETAILS = "UPDATE_SUBSCRIPTION_DETAILS";
export const UPDATE_HAIRDRESS_INFO = "UPDATE_HAIRDRESS_INFO";
export const GET_ALL_HAIRDRESSERS = "GET_ALL_HAIRDRESSERS";

// CLIENT
export const GET_CLIENT_INFO = "GET_CLIENT_INFO";
export const POST_BOOK_PRESTA = "POST_BOOK_PRESTA";
export const TOGGLE_CLIENT = "TOGGLE_CLIENT";

//AUTH
export const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT";
export const TOGGLE_CLIENT_LOADING = "TOGGLE_CLIENT_LOADING";
export const TOGGLE_CLIENT_UPDATE = "TOGGLE_CLIENT_UPDATE";

//APPOINTMENT
export const TOGGLE_APPOINTMENT = "TOGGLE_APPOINTMENT";
export const GET_ALL_APPOINTMENT_CLIENT = "GET_ALL_APPOINTMENT_CLIENT";
//HYPOTHESE
export const TOGGLE_HYP = "TOGGLE_HYP";
export const GET_ALL_HYP = "GET_ALL_HYP";
export const POST_HYP = "POST_HYP";

//CONTACT
export const POST_CONTACT_MESSAGE = "POST_CONTACT_MESSAGE";
export const TOGGLE_CONTACT = "TOGGLE_CONTACT";

//SUBSCRIBE
export const POST_SUBSCRIBE = "POST_SUBSCRIBE";

//PRESTATION
export const GET_ALL_PRESTATION = "GET_ALL_PRESTATION";
export const GET_ALL_PRESTATION_CLIENT = "GET_ALL_PRESTATION_CLIENT";
export const POST_NEW_PRESTATION = "POST_NEW_PRESTATION";
export const UPDATE_PRESTATION = "UPDATE_PRESTATION";
export const TOGGLE_PRESTATION = "TOGGLE_PRESTATION";

// FUNCTIONNALITY
export const GET_ALL_FUNCTIONNALITY = "GET_ALL_FUNCTIONNALITY";
export const POST_NEW_FUNCTIONNALITY = "POST_NEW_FUNCTIONNALITY";
export const UPDATE_FUNCTIONNALITY = "UPDATE_FUNCTIONNALITY";
export const TOGGLE_FUNCTIONNALITY = "TOGGLE_FUNCTIONNALITY";

// PRODUCT
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const POST_NEW_PRODUCT = "POST_NEW_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const TOGGLE_PRODUCT = "TOGGLE_PRODUCT";
export const GET_PRODUCT_INFO = "GET_PRODUCT_INFO";

// DATA
export const GET_ALL_TOWNS = "GET_ALL_TOWNS";
export const GET_ALL_DEPARTMENTS = "GET_ALL_DEPARTMENTS";

//ERROR
export const SET_ERRORS = "SET_ERRORS";
