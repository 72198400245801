import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ServicesAdmin from "../../components/admin/ServicesAdmin";
import Sidebar from "../../components/layout/Sidebar";

import Validate from "../../utils/form/Validate";

import {
  postNewPrestations,
  getAllPrestations,
  updatePrestation,
} from "../../actions/prestationActions";
import { logoutClient } from "../../actions/authActions";

const ServicesAdminPage = ({
  info_hairdresser,
  logoutClient,
  clientObj,
  getAllPrestations,
  all_prestations,
  updatePrestation,
  postNewPrestations,
  prestation_toggle,
}) => {
  const [prestation, setPrestation] = useState({
    presta_item: "",
    presta_item_selection: "",
    presta_isActive: true,
    presta_isFeminin: true,
    presta_isNappy: true,
    presta_errors: { prestation: "" },
  });

  useEffect(() => {
    getAllPrestations();
  }, [getAllPrestations, prestation_toggle]);

  const handleChangeCheckboxes = (e) => {
    const { name, checked } = e.target;

    setPrestation({
      ...prestation,
      [name]: checked,
    });
  };

  // Prestations
  const handleChange = (e, type, id) => {
    e.preventDefault();
    const { name, value } = e.target;

    switch (type) {
      case "update":
        setPrestation((prestation) => ({
          ...prestation,
          presta_item_selection: id,
          presta_item: e.target.value,
        }));

        break;

      default:
        setPrestation((prestation) => ({
          ...prestation,
          [name]: value,
        }));
        break;
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = { ...prestation.errors, ...Validate(name, value).errors };
    setPrestation({ ...prestation, errors: { ...error } });
  };

  const handleSubmit = (e, presta_type, presta_id, isActive) => {
    e.preventDefault();
    const {
      presta_item,
      presta_item_selection,
      presta_isActive,
      presta_isNappy,
      presta_isFeminin,
    } = prestation;

    switch (presta_type) {
      case "update":
        updatePrestation({
          id: presta_item_selection,
          prestation_update: presta_item,
          name: presta_type,
        });

        break;

      case "delete":
        updatePrestation({ presta_id, presta_type });

        break;

      case "add":
        postNewPrestations({
          presta_item,
          presta_isNappy,
          presta_isFeminin,
        });
        break;

      default:
        updatePrestation({
          id: presta_id,
          name: presta_type,
          checked: isActive,
        });
        break;
    }

    setPrestation({
      presta_item: "",
      presta_item_selection: "",
      presta_isActive: true,
      presta_isFeminin: true,
      presta_isNappy: true,
      presta_errors: { prestation: "" },
    });
  };

  // Logout
  const handleLogOut = () => {
    logoutClient();
  };

  return (
    <>
      <Sidebar clientObj={clientObj} onLogOut={handleLogOut} />
      <ServicesAdmin
        info_hairdresser={info_hairdresser}
        all_prestations={all_prestations}
        prestation={prestation}
        onBlur={handleBlur}
        onChange={handleChange}
        onChangeCheckbox={handleChangeCheckboxes}
        onSubmit={handleSubmit}
      />
    </>
  );
};

ServicesAdminPage.propTypes = {
  logoutClient: PropTypes.func.isRequired,
  updatePrestation: PropTypes.func.isRequired,
  getAllPrestations: PropTypes.func.isRequired,
  all_prestations: PropTypes.array.isRequired,
  postNewPrestations: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  errors: state.errors,
  prestation_toggle: state.prestation.prestation_toggle,
  all_prestations: state.prestation.all_prestations,
  clientObj: state.auth.clientObj,
});

export default connect(mapStateToProps, {
  logoutClient,
  getAllPrestations,
  updatePrestation,
  postNewPrestations,
})(ServicesAdminPage);
